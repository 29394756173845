import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { makeStyles } from '@mui/styles';
import useConfig from 'UI/customHooks/useConfig';
import classnames from 'classnames';
import { get } from 'lodash';
import React, { useCallback } from 'react';

import CompanySpecificSkillLogo from '../../../../components/CompanySpecificSkillLogo/CompanySpecificSkillLogo';

import styles from './SelectedSkills.styles';

const useStyles = makeStyles(styles);

const SelectedSkills = ({
  withInteractiveSkills = true,
  isEditMode,
  skills,
  onClick,
  disabled,
}) => {
  const classes = useStyles();
  const config = useConfig();

  const replaceDefaultLogo = get(
    config,
    'cms.pages.skills.buttonOptions.replaceDefaultLogo',
    false
  );

  const onClickCallback = useCallback(
    clickedSkillId => {
      if (isEditMode && onClick) {
        onClick(clickedSkillId);
      }
    },
    [onClick, isEditMode]
  );

  return (
    <div
      className={classnames(skills.length ? classes.skillsWrapper : null, {
        [classes.noEventsAllowed]: !withInteractiveSkills,
      })}
      data-test="selected-skills-part"
    >
      {skills?.map(skill => (
        <Button
          sx={{
            wordBreak: 'break-word',
          }}
          key={skill._id}
          variant="contained"
          className={classes.selectedSkill}
          onClick={() => onClickCallback(skill._id)}
          disabled={disabled}
          data-test="skill-button"
        >
          {skill.synonym?.companySpecific?.isLogoVisible && (
            <CompanySpecificSkillLogo height="20px" replaceDefaultLogo={replaceDefaultLogo} />
          )}
          {skill.synonym?.synonymString}
          {withInteractiveSkills && <Icon className={classes.selectIcon}>clear</Icon>}
        </Button>
      ))}
    </div>
  );
};

export default SelectedSkills;
