import React, { Fragment } from 'react';
import classnames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { Toolbar, AppBar, Grid, Tabs, Tab } from '@mui/material';
import { Link, withRouter } from 'react-router-dom';

import { Container } from '../../../ui/layouts/components';
import styles from './styles';

const SubMenu = ({ classes, items, location, justify = 'center', classNames }) => {
  return (
    <div className={classes.rootWrapper}>
      <div className={classes.root}>
        <AppBar position="static" className={classes.AppBar} color="inherit" elevation={0}>
          <Toolbar className={classes.Toolbar} disableGutters>
            <Container className={classes.Container}>
              <Grid
                item
                xs
                className={classnames(classes.navContainer, classNames)}
                container
                justifyContent={justify}
                data-test="nav-container"
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons={'auto'}
                  allowScrollButtonsMobile
                  className={classes.tabWrapper}
                  data-test="learning-navigation_bar-tabs"
                  aria-label="Learning navigation tabs"
                >
                  {items.length > 0 &&
                    items.map(
                      ({
                        dataTest,
                        url,
                        label,
                        isDisabled,
                        isReport,
                        onReportDownload,
                        render,
                        notExactMatch,
                        isActiveLink,
                        isButtonHidden,
                      }) => {
                        let isCurrentLinkActive;
                        // useful for when tab should be "active" if its url partly matches the `location.pathname`.
                        // Example: /app/learn/course !== /app/learn/course/:sourceName
                        if (notExactMatch) {
                          const urlPathname = url.split('?', 2)[0];
                          isCurrentLinkActive = location.pathname.startsWith(urlPathname);
                        } else {
                          isCurrentLinkActive = url === location.pathname;
                        }
                        if (isActiveLink) {
                          isCurrentLinkActive = isActiveLink;
                        }

                        if (isButtonHidden) {
                          return null;
                        }

                        if (render) {
                          return (
                            <Fragment key={url}>
                              {render(
                                `${classes.link} ${isCurrentLinkActive ? classes.active : ''}`
                              )}
                            </Fragment>
                          );
                        }
                        if (isDisabled || isReport) {
                          return (
                            <span
                              data-test={dataTest}
                              key={url}
                              className={`${classes.link} ${isDisabled ? classes.disabled : ''}`}
                              onClick={isReport ? onReportDownload : undefined}
                            >
                              {label}
                            </span>
                          );
                        }
                        return (
                          <Tab disableTouchRipple>
                            <Link
                              data-test={dataTest}
                              key={url}
                              to={url}
                              className={`${classes.link} ${
                                isCurrentLinkActive ? classes.active : ''
                              }`}
                            >
                              {label}
                            </Link>
                          </Tab>
                        );
                      }
                    )}
                </Tabs>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(SubMenu));
