import { Icon, IconButton, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { getRandomString } from 'UI/pages/restrict/util';
import { getIsExternalUrl } from '@people-analytix/util';
import { navigateExternal, useT } from 'UI/lib';
import defaults from 'lodash/defaults';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styles from './MultipleDocumentUrlInputs.styles';

const useStyles = makeStyles(styles);

export const MultipleDocumentUrlInputs = ({
  urls,
  mergeUrls,
  setIsValidExternalUrl,
  label,
  isDisabled,
}) => {
  const t = useT();

  const extractAppendedUrls = data => {
    let documentsObject = {};
    const documentsArray = data?.split(',');
    if (documentsArray?.length) {
      documentsArray.map(url => {
        return (documentsObject[`documentUrl_${getRandomString()}`] = decodeURIComponent(url));
      });
    }
    return documentsObject;
  };

  const [appendedDocumentInputs, setAppendedDocumentInputs] = useState(() =>
    defaults(extractAppendedUrls(urls), {})
  );
  const [numberOfDocumentsAdded, setNumberOfDocumentsAdded] = useState(true);
  const classes = useStyles();

  const mergeAddedDocuments = useCallback(() => {
    const allAddedDocuments = [];
    Object.keys(appendedDocumentInputs).map(key => {
      appendedDocumentInputs[key].length > 0 &&
        allAddedDocuments.push(encodeURIComponent(appendedDocumentInputs[key]));
      return allAddedDocuments;
    });
    return allAddedDocuments;
  }, [appendedDocumentInputs]);

  const validateUrls = useCallback(() => {
    const isInvalidInput = Object.keys(appendedDocumentInputs)
      .map(item => appendedDocumentInputs[item])
      .some(x => x.length && !getIsExternalUrl(x));
    setIsValidExternalUrl(!isInvalidInput);
  }, [appendedDocumentInputs, setIsValidExternalUrl]);

  const openDocumentLink = useCallback(
    url => {
      let navigateUrl = url;
      if (!getIsExternalUrl(navigateUrl)) {
        navigateUrl = 'http://' + navigateUrl;
      }

      navigateExternal(navigateUrl, true);
    },
    [navigateExternal, getIsExternalUrl]
  );

  useEffect(() => {
    const numberOfDocumentsAppended = Object.keys(appendedDocumentInputs).length;
    const documentUrls = mergeAddedDocuments().join(',');
    setNumberOfDocumentsAdded(numberOfDocumentsAppended);
    if (typeof setIsValidExternalUrl !== 'undefined') {
      validateUrls();
    }
    mergeUrls(documentUrls);
  }, [appendedDocumentInputs, mergeAddedDocuments, mergeUrls, validateUrls]);

  const setFormOnChange = useCallback(
    (field, value) => {
      setAppendedDocumentInputs({ ...appendedDocumentInputs, [field]: value });
    },
    [appendedDocumentInputs]
  );

  const lastAppendedDocument = Object.keys(appendedDocumentInputs).slice(-1)[0];

  const appendNewDocumentInput = useCallback(() => {
    numberOfDocumentsAdded < 5 &&
      setAppendedDocumentInputs({
        ...appendedDocumentInputs,
        [`documentUrl_${getRandomString()}`]: '',
      });
  }, [appendedDocumentInputs, numberOfDocumentsAdded]);

  const removeDocumentInput = inputName => {
    const newState = Object.keys(appendedDocumentInputs).reduce((object, key) => {
      if (key !== inputName) {
        object[key] = appendedDocumentInputs[key];
      }
      return object;
    }, {});

    setAppendedDocumentInputs(newState);
  };

  return (
    <Fragment>
      {Object.keys(appendedDocumentInputs).map((item, index) => {
        return (
          <TextField
            variant="standard"
            id={item}
            key={item}
            label={`${label} (${index + 1})`}
            value={`${appendedDocumentInputs[item] || ''}`}
            placeholder="https://..."
            type="url"
            onChange={e => setFormOnChange(item, e.target.value)}
            disabled={isDisabled}
            data-test="personal-development__document-url"
            multiline
            maxRows="2"
            InputProps={{
              endAdornment:
                isDisabled && appendedDocumentInputs[item].length > 0 ? (
                  <InputAdornment position="end">
                    {
                      <Icon
                        className={classes.launchIcon}
                        onClick={() => openDocumentLink(appendedDocumentInputs[item])}
                      >
                        launch
                      </Icon>
                    }
                  </InputAdornment>
                ) : (
                  index > 0 && (
                    <InputAdornment position="end" classes={{ root: classes.inputIconClearRoot }}>
                      <IconButton onClick={() => removeDocumentInput(item)} size="large">
                        <Icon>clear</Icon>
                      </IconButton>
                    </InputAdornment>
                  )
                ),
            }}
          />
        );
      })}
      {appendedDocumentInputs[lastAppendedDocument] && numberOfDocumentsAdded < 5 && !isDisabled && (
        <Button
          className={classes.addMoreDocumentsButton}
          variant="contained"
          onClick={appendNewDocumentInput}
          data-test={'add_more_documents'}
        >
          <Icon>add</Icon>
          {t('restrict:personal_development.add_more_documents')}
        </Button>
      )}
    </Fragment>
  );
};
