import get from 'lodash/get';

const extractRootFieldNameFromQuery = query =>
  get(query, 'definitions.0.selectionSet.selections.0.name.value');

const updateQueryCache = ({ cache, query, variables, update, isCacheRequired }) => {
  let oldResults;
  try {
    oldResults = cache.readQuery({ query, variables });
  } catch (e) {
    if (isCacheRequired) {
      throw e;
    }
    const hasNoCacheForQuery = true;
    return hasNoCacheForQuery;
  }
  const dataFieldName = extractRootFieldNameFromQuery(query);
  const oldData = oldResults && oldResults[dataFieldName];
  if (!oldData) {
    return !oldData;
  }
  const newData = update(oldData);
  const didTransformFail = newData === null;
  if (didTransformFail) {
    return didTransformFail;
  }
  cache.writeQuery({
    query,
    variables,
    data: {
      [dataFieldName]: newData,
    },
  });
  return true;
};

export default updateQueryCache;
