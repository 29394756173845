import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DialogActions, DialogContent } from '@mui/material/';
import Button from '@mui/material/Button';
import get from 'lodash/get';
import React, { useMemo, useState } from 'react';
import SkillExpertise from 'UI/components/SkillExpertise';
import useConfig from 'UI/customHooks/useConfig';
import { clientLogger, useT } from 'UI/lib';
import { AutoComplete } from 'UI/components';
import { useMessageContext } from 'UI/contexts';
import useAddCompanySynonyms from '../hooks/useAddCompanySynonyms';
import useAutocompleteGlobalSearch from 'UI/pages/restrict/customerSpecificSkills/CustomizeSynonyms/hooks/useAutocompleteGlobalSearch';
import { MdInfoOutline } from 'react-icons/md';

const CreateCompanySynonymModal = ({ onFinish }) => {
  const message = useMessageContext();
  const t = useT();
  const config = useConfig();

  const [searchSkillSynonym, setSearchSkillSynonym] = useState(null);
  const [selectSynonym, setSelectSynonym] = useState(null);
  const [form, setForm] = useState({
    synonymId: '',
    customName: '',
    description: '',
    isLogoVisible: false,
    levelDescriptions: {
      Beginner: '',
      Experience: '',
      Expert: '',
      SuperExpert: '',
    },
  });

  const supportedLevels = useMemo(() => get(config, 'cms.features.skills.expertiseLevels'), [
    config,
  ]);

  const disableSubmit = useMemo(() => {
    const { description, ...restForm } = form;
    return Object.entries(restForm).some(([, value]) => value === '' || value === null);
  }, [form]);

  const { addCompanySpecific } = useAddCompanySynonyms(form);

  const { autoCompleteItems, onSelect, onClear, resetSearch } = useAutocompleteGlobalSearch({
    searchSkillSynonym,
    setSelectSynonym,
    setSearchSkillSynonym,
    setForm,
  });

  const handleTextField = (e, name) => {
    setForm(prevState => ({ ...prevState, [name]: e.target.value }));
  };

  const handleLevelDescription = (e, level) => {
    setForm(prevState => ({
      ...prevState,
      levelDescriptions: {
        ...prevState.levelDescriptions,
        [level]: e.target.value,
      },
    }));
  };

  const handleSwitch = (e, name) => {
    setForm(prevState => ({ ...prevState, [name]: e.target.checked }));
  };

  const handleClose = () => {
    resetSearch();
    onFinish();
  };

  const onSubmit = e => {
    e.preventDefault();
    addCompanySpecific()
      .then(() => {
        message.setMessage({
          text: t('restrict:company_view.customer_synonyms.default.dialog.created'),
          type: 'success',
        });
        handleClose();
      })
      .catch(e => {
        const isSynonymIdAlreadyTaken = e.message === 'CONFLICT';

        if (isSynonymIdAlreadyTaken) {
          message.setMessage({
            text: t(
              'restrict:company_view.customer_synonyms.default.dialog.synonym_id_already_taken',
              {
                name: selectSynonym.name,
              }
            ),
            type: 'info',
          });
          return;
        }

        clientLogger.error(e);

        message.setMessage({
          text: t('common:default_error_message'),
          type: 'error',
        });
      });
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Grid container rowSpacing={8} sx={{ flexDirection: 'column' }}>
            <Grid item>
              <TextField
                variant="standard"
                maxRows={2}
                label={t('restrict:company_view.customer_synonyms.default.column.custom_name')}
                onChange={e => handleTextField(e, 'customName')}
              />
            </Grid>
            <Grid item>
              <FormControl data-test="skill-inventory-search">
                <AutoComplete
                  id="search-skills-combobox"
                  autoFocus
                  placeholder={t(
                    'restrict:company_view.customer_synonyms.default.dialog.name_input_placeholder'
                  )}
                  items={autoCompleteItems}
                  onSearch={setSearchSkillSynonym}
                  delay={150}
                  fullWidth
                  clearInputOnSelect={false}
                  clearOnBlur
                  onSelect={onSelect}
                  selectedItem={selectSynonym || null}
                  onClear={onClear}
                  isOptionEqualToValue={(option, value) =>
                    option.synonymId === value.id ||
                    option.synonymId === value.synonymId ||
                    value === ''
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              sx={{
                paddingTop: 2,
                display: 'flex',
              }}
            >
              <MdInfoOutline color="#8A8A8A" />
              <Typography sx={{ color: '#8A8A8A', fontSize: 12, marginRight: 2 }}>
                {t('restrict:company_view.customer_synonyms.default.column.language')}:
              </Typography>
              <Typography sx={{ textTransform: 'uppercase', fontSize: 12 }}>
                {selectSynonym?.language}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                multiline
                maxRows={3}
                label={t(
                  'restrict:company_view.customer_synonyms.default.dialog.write_description'
                )}
                placeholder={t(
                  'restrict:company_view.customer_synonyms.default.dialog.input_placeholder'
                )}
                sx={{
                  textarea: {
                    '&::-webkit-scrollbar': { width: 7 },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#D9D9D9',
                      borderRadius: 100,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#808080',
                      borderRadius: 100,
                    },
                    scrollbarGutter: 'stable',
                  },
                }}
                onChange={e => handleTextField(e, 'description')}
              />
            </Grid>
            {Object.entries(form?.levelDescriptions).map(([key, value], index) => {
              if (key === 'SuperExpert' && supportedLevels.length !== 4) {
                return null;
              }
              return (
                <Grid item key={`${key}-modal-input`}>
                  <TextField
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: 4 }}>
                        <SkillExpertise level={key} disableTitleText />{' '}
                        <Typography>
                          (
                          {t('restrict:company_view.customer_synonyms.default.dialog.star_info', {
                            number: index + 1,
                          })}
                          )
                        </Typography>
                      </Box>
                    }
                    variant="standard"
                    multiline
                    maxRows={3}
                    placeholder={t(
                      'restrict:company_view.customer_synonyms.default.dialog.input_placeholder'
                    )}
                    sx={{
                      textarea: {
                        '&::-webkit-scrollbar': { width: 7 },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: '#D9D9D9',
                          borderRadius: 100,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#808080',
                          borderRadius: 100,
                        },
                        scrollbarGutter: 'stable',
                      },
                    }}
                    value={value || ''}
                    onChange={e => handleLevelDescription(e, key)}
                  />
                </Grid>
              );
            })}
            <Grid item>
              <FormControlLabel
                control={<Switch onChange={e => handleSwitch(e, 'isLogoVisible')} />}
                label={t('restrict:company_view.customer_synonyms.default.dialog.logo_input_label')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ textTransform: 'uppercase' }}
            onClick={() => handleClose()}
          >
            {t('common:cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ textTransform: 'uppercase' }}
            disabled={disableSubmit}
          >
            {t('common:create')}
          </Button>
        </DialogActions>
      </form>
    </Box>
  );
};

export default CreateCompanySynonymModal;
