import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import defaults from 'lodash/defaults';
import React, { useCallback, useEffect, useState } from 'react';
import { useT } from 'UI/lib';
import Notes from '../../../../../../components/Notes/Notes';
import SelectedSkills from '../../../components/SelectedSkills';
import DateInput from '../../../DevelopmentPlan/AddMoreGoalsModals/components/DateInput';
import SubmitArea from '../../../DevelopmentPlan/AddMoreGoalsModals/components/SubmitArea';
import { useStatuses } from '../../useStatuses';
import { MultipleDocumentUrlInputs } from '../MultipleDocumentUrlInputs/MultipleDocumentUrlInputs';
import styles from './AddKnowledgeExchangeToPersonalPlanDialog.styles';

const useStyles = makeStyles(styles);

export const AddKnowledgeExchangeToPersonalPlanDialog = ({
  initialData,
  isEditMode = false,
  isViewOnly = false,
  onSubmit,
  onFinish,
}) => {
  const [mergedUrls, setMergedUrls] = useState('');

  const t = useT();
  const classes = useStyles();
  const statuses = useStatuses();

  const [form, setForm] = useState(() =>
    defaults(initialData, {
      title: '',
      contactEmail: '',
      status: 'planned',
      notes: '',
      skills: null,
    })
  );

  const setFormOnChange = useCallback((field, value) => setForm({ ...form, [field]: value }), [
    form,
  ]);

  useEffect(() => {
    setForm(form => ({ ...form, documentUrl: mergedUrls }));
  }, [mergedUrls]);

  const handleSubmit = useCallback(() => {
    const newData = {
      ...form,
      startedAt: new Date(form.startedAt),
    };

    delete newData.skills;
    if (form.skills) {
      newData.skillSynonymIds = form.skills.map(({ synonym }) => synonym.synonymId);
    }

    onSubmit(newData);
    onFinish();
  }, [onSubmit, onFinish, form]);

  const handleChange = (field, value) => setFormOnChange(field, value);

  const isSubmitButtonEnabled = form.title;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={10} className={classes.detailsSection}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              id="title"
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_title'
              )}
              required
              value={form.title}
              error={!Boolean(form.title)}
              type="text"
              onChange={e => handleChange('title', e.target.value)}
              disabled={isViewOnly}
              data-test="knowledge-exchange__title"
              multiline
              maxRows="2"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              id="startedAt"
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_started_at'
              )}
              value={form.startedAt}
              error={!Boolean(form.startedAt)}
              required
              helperText={t('restrict:personal_development.started_at_date.help_text')}
              onDateChange={(id, value) => handleChange('startedAt', value)}
              disabled={isViewOnly}
              dataTest="knowledge-exchange__started-date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl data-test="status-select-form">
              <InputLabel variant="standard">{t('restrict:pd.status_field')}</InputLabel>
              <Select
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                disabled={isViewOnly}
                data-test="knowledge-exchange__status"
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {form.skills && (
            <Grid item xs={12}>
              <div data-test="skills-part">
                <InputLabel variant="standard">
                  {t(
                    'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_skills'
                  )}
                </InputLabel>
                <SelectedSkills
                  isEditMode={!isViewOnly}
                  skills={form.skills}
                  withInteractiveSkills={false}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              variant="standard"
              id="contactEmail"
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_contact_email'
              )}
              value={form.contactEmail}
              type="email"
              disabled={isViewOnly}
              data-test="knowledge-exchange__contact-email"
              multiline
              maxRows="2"
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleDocumentUrlInputs
              mergeUrls={setMergedUrls}
              isDisabled={isViewOnly}
              urls={form.documentUrl}
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_document_url'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Notes
              value={form.notes}
              onValueChange={value => handleChange('notes', value)}
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_notes'
              )}
              disabled={isViewOnly}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={20}>
        <SubmitArea
          isDisabled={isViewOnly}
          onFinish={onFinish}
          isSubmitButtonEnabled={isSubmitButtonEnabled}
          buttonLabel={isEditMode ? t('restrict:pd.update_goals.save_button') : t('common:add')}
          onSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};
