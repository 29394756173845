const PendingIconSvg = ({ height = 32, width = 32, fill = '#323232' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8 16.0269L19.9 16.0133L25.0817 21.2314L30.2999 16.0497L26.3999 16.036C26.4201 10.2901 21.7824 5.61979 16.0364 5.59967C13.9954 5.59253 12.0953 6.18388 10.4928 7.19228L12.3841 9.09691C13.4652 8.51569 14.7143 8.19506 16.0273 8.19966C20.3303 8.21472 23.815 11.7239 23.8 16.0269ZM21.5072 24.8069L19.6159 22.9023C18.5219 23.4705 17.2857 23.8042 15.9727 23.7996C11.6697 23.7845 8.18499 20.2753 8.20005 15.9723L12.1 15.986L6.91826 10.7678L1.70009 15.9495L5.60006 15.9632C5.57995 21.7092 10.2176 26.3794 15.9636 26.3995C18.0046 26.4067 19.9047 25.8153 21.5072 24.8069Z"
      fill={fill}
    />
    <path
      d="M12.8 10.4995V13.6995H12.8053L12.8 13.7049L14.9333 15.8329L12.8 17.9662L12.8053 17.9715H12.8V21.1662H19.2V17.9715H19.1947L19.2 17.9662L17.0667 15.8329L19.2 13.7049L19.1947 13.6995H19.2V10.4995H12.8ZM18.1333 18.2329V20.0995H13.8667V18.2329L16 16.0995L18.1333 18.2329ZM16 15.5662L13.8667 13.4329V11.5662H18.1333V13.4329L16 15.5662Z"
      fill={fill}
    />
  </svg>
);

export default PendingIconSvg;
