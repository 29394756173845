import gql from 'graphql-tag';

const GET_SKILL_INVENTORY_SKILL = gql`
  query GET_SKILL_INVENTORY_SKILL(
    $organizationId: ID!
    $skillId: ID!
    $showWholeOrganization: Boolean
  ) {
    skillInventorySkill(
      skillId: $skillId
      organizationId: $organizationId
      showWholeOrganization: $showWholeOrganization
    ) {
      _id: skillId
      skillId
      skill {
        _id
        trends {
          trendIcon
        }
        synonym {
          _id
          synonymId
          synonymString
          companySpecific {
            _id
            isLogoVisible
          }
        }
      }
      workforceCount
      workforcePerc
      workforceLikeCount
      workforceImproveCount
      expertiseLevels {
        level
        count
        perc
        likeCount
        improveCount
      }
    }
  }
`;

const GET_SKILL_INVENTORY = gql`
  query GET_SKILL_INVENTORY(
    $perPage: Int
    $page: Int
    $sort: String
    $filter: OrganizationSkillInputFilterType
    $policiesPreset: PoliciesPresetInput
  ) {
    skillInventory(sort: $sort, perPage: $perPage, page: $page, filter: $filter) {
      items {
        _id
        skillId
        skill {
          _id
          trends {
            _id
            trendIcon
            replaceability
            demand
          }
          synonym(policiesPreset: $policiesPreset) {
            _id
            synonymId
            synonymString
            companySpecific {
              _id
              isLogoVisible
            }
          }
        }
        workforceCount
        workforcePerc
        workforceLikeCount
        workforceImproveCount
        expertiseLevels {
          name
          level
          count
          perc
          likeCount
          improveCount
        }
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;

const GET_SKILL_INVENTORY_SKILL_USERS = gql`
  query GET_SKILL_INVENTORY_SKILL_USERS(
    $organizationId: ID
    $skillId: ID!
    $perPage: Int
    $page: Int
    $sort: SkillInventorySkillUsersSortFieldEnum
    $dir: SkillInventorySkillUsersSortDirEnum
    $search: String
  ) {
    skillInventorySkillUsers(
      page: $page
      perPage: $perPage
      filter: { skillId: $skillId, organizationId: $organizationId, search: $search }
      sort: { field: $sort, dir: $dir }
    ) {
      items {
        _id
        skillId
        userId
        isLiked
        isImprove
        expertise
        createdAt
        updatedAt
        proofPointId
        managerAssessment {
          confirmedAt
          status
          confirmedBy {
            _id
            profile {
              name
            }
          }
        }
        user {
          _id
          profile {
            name
            avatar
            email
          }
          organizations {
            _id
            roles
            organization {
              _id
              name
            }
          }
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export { GET_SKILL_INVENTORY_SKILL, GET_SKILL_INVENTORY, GET_SKILL_INVENTORY_SKILL_USERS };
