import gql from 'graphql-tag';

export const GET_ONBOARDING_OPPORTUNITIES = gql`
  query GET_ONBOARDING_OPPORTUNITIES(
    $perPage: Int
    $page: Int
    $filter: MarketOpportunityFilterInputType
    $policiesPreset: PoliciesPresetInput
  ) {
    marketOpportunities(perPage: $perPage, page: $page, filter: $filter) {
      __typename
      count
      items {
        __typename
        _id
        follow
        covered
        job {
          _id
          trendIcon
          name
        }
        missing {
          mostRelevant {
            _id
            skillId
            skill {
              synonym(policiesPreset: $policiesPreset) {
                _id
                synonymId
                synonymLang
                synonymString
                companySpecific {
                  _id
                  isLogoVisible
                  definition
                }
              }
            }
          }
        }
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;
