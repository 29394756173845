import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { Component, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import { AddSkillSuggestion, ButtonCustom, DialogCustom } from '../components';
import {
  AddSkillsManually,
  CapabilityScanDocument,
  CapabilitySkillsSuggestions,
  CapaSkillsByJob,
  ExplainFeatureVideo,
  ExplainVideo,
  JobAds,
  JobSkillsSuggestions,
  MobilityCalculator,
  NewsDetails,
  PrivacyPolicy,
  RefineSkills,
  RefineSkillsByJob,
  ScanDocument,
  SearchAndAddCompanySpecificSkills,
  TermsAndConditions,
} from '../containers';
import AddEditCapabilities from '../containers/AddEditCapabilities';
import ManuallyAddedSkillsValidation from '../containers/ManuallyAddedSkillsValidation';
import ReportDownload from '../containers/ReportDownload/ReportDownload';
import ReportsDownloadTitle from '../containers/ReportDownload/ReportsDownloadTitle';
import { SelectGlobalOrganization } from '../containers/SelectOrganization';
import CreateCompanySynonymModal from '../pages/restrict/customerSpecificSkills/CustomizeSynonyms/CreateCompanySynonymModal/CreateCompanySynonymModal';
import ImportCompanySynonymsModal from '../pages/restrict/customerSpecificSkills/CustomizeSynonyms/ImportCompanySynonymsModal/ImportCompanySynonymsModal';
import UpdateCompanySynonymModal from '../pages/restrict/customerSpecificSkills/CustomizeSynonyms/UpdateCompanySynonymModal/UpdateCompanySynonymModal';
import { CheckIn } from '../pages/restrict/personalDevelopment/DevelopmentPlan/AddMoreGoalsModals/CheckIn/CheckIn';
import { ManualCreation } from '../pages/restrict/personalDevelopment/DevelopmentPlan/AddMoreGoalsModals/ManualCreation/ManualCreation';
import { AddCourseToPersonalPlanDialog } from 'UI/pages/restrict/personalDevelopment/RecommendationsLXP/components/Learning/AddCourseToPersonalPlanDialog';
import { AddProjectToPersonalPlanDialog } from '../pages/restrict/personalDevelopment/Recommendations/components/Projects/AddProjectToPersonalPlanDialog';
import { AddKnowledgeExchangeToPersonalPlanDialog } from '../pages/restrict/personalDevelopment/Recommendations/components/SharedSkills/AddKnowledgeExchangeToPersonalPlanDialog';
import ReplaceSkillWithSynonymModal from '../pages/restrict/skills/SkillsTab/ReplaceSkillWithSynonymModal';
import SynonymInfo from 'UI/pages/restrict/customerSpecificSkills/CustomizeSynonyms/SynonymsTable/SynonymInfo';

const ModalsContext = React.createContext();

/* eslint-disable react/no-unused-state */

function Modals({ onClose, title }) {
  const personalDevelopmentTitleStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  if (!i18next.t) {
    return {};
  }
  return {
    ADD_SKILLS_MANUALLY: {
      children: <AddSkillsManually onFinish={() => onClose('ADD_SKILLS_MANUALLY')} />,
    },
    JOB_ADS: {
      children: <JobAds onFinish={() => onClose('JOB_ADS')} />,
      actions: (
        <ButtonCustom onClick={() => onClose('JOB_ADS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
      maxWidth: false,
    },
    JOB_SKILLS_SUGGESTIONS: {
      children: <JobSkillsSuggestions onFinish={() => onClose('JOB_SKILLS_SUGGESTIONS')} />,
    },
    NEWS_DETAILS: {
      children: <NewsDetails />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('NEWS_DETAILS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    PRIVACY_POLICY: {
      title: title || i18next.t('common:privacy_policy_title_modal'),
      children: <PrivacyPolicy />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('PRIVACY_POLICY')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    REFINE_SKILLS: {
      title: i18next.t('restrict:refine_skills_title_modal'),
      children: <RefineSkills />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('REFINE_SKILLS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    REFINE_SKILLS_BY_JOB: {
      children: <RefineSkillsByJob onFinish={() => onClose('REFINE_SKILLS_BY_JOB')} />,
      fullScreen: false,
    },
    SCAN_DOCUMENT: {
      children: <ScanDocument onFinish={() => onClose('SCAN_DOCUMENT')} />,
      fullScreen: false,
    },
    TERMS_AND_CONDITION: {
      title: title || i18next.t('common:terms_modal_title'),
      children: <TermsAndConditions />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('TERMS_AND_CONDITION')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    EXPLAIN_VIDEO: {
      children: <ExplainVideo />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('EXPLAIN_VIDEO')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    EXPLAIN_VIDEO_BUTTON: {
      children: <ExplainFeatureVideo />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('EXPLAIN_VIDEO_BUTTON')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    USER_VALIDATE_SKILLS: {
      title: i18next.t('restrict:validate_skills_modal_title'),
      children: <ManuallyAddedSkillsValidation />,
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('USER_VALIDATE_SKILLS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    ADD_EDIT_CAPABILITY: {
      children: <AddEditCapabilities onFinish={() => onClose('ADD_EDIT_CAPABILITY')} />,
      fullScreen: false,
    },
    CAPA_SCAN_DOCUMENT: {
      children: <CapabilityScanDocument onFinish={() => onClose('CAPA_SCAN_DOCUMENT')} />,
      fullScreen: false,
    },
    CAPA_SKILLS_BY_JOB: {
      children: <CapaSkillsByJob onFinish={() => onClose('CAPA_SKILLS_BY_JOB')} />,
      fullScreen: false,
    },
    MOBILITY_CALCULATOR: {
      children: <MobilityCalculator onFinish={() => onClose('MOBILITY_CALCULATOR')} />,
      fullScreen: false,
    },
    CAPABILITY_SKILLS_SUGGESTION: {
      children: (
        <CapabilitySkillsSuggestions onFinish={() => onClose('CAPABILITY_SKILLS_SUGGESTION')} />
      ),
      fullScreen: false,
    },
    SEARCH_COMPANY_SPECIFIC_SKILLS: {
      children: (
        <SearchAndAddCompanySpecificSkills
          onFinish={() => onClose('SEARCH_COMPANY_SPECIFIC_SKILLS')}
        />
      ),
      fullScreen: false,
    },
    SELECT_ORGANIZATION: {
      children: <SelectGlobalOrganization onFinish={() => onClose('SELECT_ORGANIZATION')} />,
      fullScreen: false,
    },
    ADD_KNOWLEDGE_EXCHANGE_TO_PERSONAL_PLAN: {
      title: i18next.t(
        'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_header'
      ),
      children: (
        <AddKnowledgeExchangeToPersonalPlanDialog
          onFinish={() => onClose('ADD_KNOWLEDGE_EXCHANGE_TO_PERSONAL_PLAN')}
        />
      ),
    },
    UPDATE_KNOWLEDGE_EXCHANGE_IN_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t(
            'restrict:personal_development.recommendations_update_knowledge_exch_in_plan_header'
          )}
        </div>
      ),
      children: (
        <AddKnowledgeExchangeToPersonalPlanDialog
          onFinish={() => onClose('UPDATE_KNOWLEDGE_EXCHANGE_IN_PERSONAL_PLAN')}
        />
      ),
    },
    ADD_COURSE_TO_PERSONAL_PLAN: {
      title: i18next.t('restrict:personal_development.recommendations_add_course_to_plan_header'),
      children: (
        <AddCourseToPersonalPlanDialog onFinish={() => onClose('ADD_COURSE_TO_PERSONAL_PLAN')} />
      ),
    },
    ADD_PROJECT_TO_PERSONAL_PLAN: {
      title: i18next.t('restrict:personal_development.recommendations_add_project_to_plan_header'),
      children: (
        <AddProjectToPersonalPlanDialog onFinish={() => onClose('ADD_PROJECT_TO_PERSONAL_PLAN')} />
      ),
    },
    UPDATE_COURSE_IN_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:personal_development.recommendations_update_course_in_plan_header')}
        </div>
      ),
      children: (
        <AddCourseToPersonalPlanDialog onFinish={() => onClose('UPDATE_COURSE_IN_PERSONAL_PLAN')} />
      ),
    },
    UPDATE_PROJECT_IN_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:personal_development.recommendations_update_project_in_plan_header')}
        </div>
      ),
      children: (
        <AddProjectToPersonalPlanDialog
          onFinish={() => onClose('UPDATE_PROJECT_IN_PERSONAL_PLAN')}
        />
      ),
    },
    ADD_CHECKIN_TO_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:pd.add_further_goals.check-in_tab')}
        </div>
      ),
      children: <CheckIn onFinish={() => onClose('ADD_CHECKIN_TO_PERSONAL_PLAN')} />,
    },
    ADD_MANUAL_GOAL_TO_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:pd.add_further_goals.add_manually_tab')}
        </div>
      ),
      children: <ManualCreation onFinish={() => onClose('ADD_MANUAL_GOAL_TO_PERSONAL_PLAN')} />,
    },
    PERSONAL_PLAN_EDIT_CHECK_IN_GOAL: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t(
            'restrict:personal_development.recommendations_update_check-in_measure_in_plan_header'
          )}
        </div>
      ),
      children: <CheckIn onFinish={() => onClose('PERSONAL_PLAN_EDIT_CHECK_IN_GOAL')} />,
    },
    PERSONAL_PLAN_EDIT_MANUALLY_ADDED_GOAL: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t(
            'restrict:personal_development.recommendations_update_manually_added_measure_in_plan_header'
          )}
        </div>
      ),
      children: (
        <ManualCreation onFinish={() => onClose('PERSONAL_PLAN_EDIT_MANUALLY_ADDED_GOAL')} />
      ),
    },
    REPORT_DOWNLOAD: {
      title: <ReportsDownloadTitle title={title} />,
      children: <ReportDownload />,
    },
    REPLACE_SKILL_WITH_SYNONYM: {
      children: (
        <ReplaceSkillWithSynonymModal onFinish={() => onClose('REPLACE_SKILL_WITH_SYNONYM')} />
      ),
    },
    CREATE_COMPANY_SYNONYM: {
      title: i18next.t('restrict:company_view.customer_synonyms.default.dialog.create_title'),
      children: <CreateCompanySynonymModal onFinish={() => onClose('CREATE_COMPANY_SYNONYM')} />,
    },
    UPDATE_COMPANY_SYNONYM: {
      title: i18next.t('restrict:company_view.customer_synonyms.default.dialog.update_title'),
      children: <UpdateCompanySynonymModal onFinish={() => onClose('UPDATE_COMPANY_SYNONYM')} />,
    },
    UPLOAD_COMPANY_SYNONYMS: {
      title: i18next.t('restrict:company_view.customer_synonyms.default.dialog.import_csv_title'),
      children: <ImportCompanySynonymsModal onFinish={() => onClose('UPLOAD_COMPANY_SYNONYMS')} />,
    },
    SUGGEST_SKILL: { children: <AddSkillSuggestion onFinish={() => onClose('SUGGEST_SKILL')} /> },
    DESCRIPTION_COMPANY_SYNONYMS: {
      children: <SynonymInfo />,
    },
  };
}

const defaultState = {};

const modals = Modals({});

Object.keys(modals).forEach(key => {
  defaultState[key] = {
    opened: false,
    params: {},
    currentTitle: modals[key].title,
  };
});

class ModalsProviderComponent extends Component {
  state = defaultState;

  open = (modal, params) => {
    this.setState({
      [modal]: Object.assign({}, this.state[modal], {
        opened: true,
        params,
      }),
    });
  };
  close = modal =>
    this.setState({
      [modal]: Object.assign({}, this.state[modal], {
        opened: false,
      }),
    });

  render() {
    const { children } = this.props;

    const ModalComponents = Modals({
      onClose: this.close,
    });

    return (
      <ModalsContext.Provider
        value={{
          state: this.state,
          reset: () => this.setState(defaultState),
          open: this.open,
          close: this.close,
        }}
      >
        {children}
        {Object.keys(ModalComponents).map(modal => {
          const props = ModalComponents[modal];
          const { opened, params = {} } = this.state[modal];
          return (
            <DialogCustom
              key={modal.toString()}
              fullScreen={props.fullScreen}
              open={opened}
              onClose={() => this.close(modal)}
              dialogTitle={params.title || props.title}
              actions={props.actions}
              {...params.dialogProps}
            >
              {React.cloneElement(props.children, { ...params })}
            </DialogCustom>
          );
        })}
      </ModalsContext.Provider>
    );
  }
}

function withModals(Children) {
  return function WrappedComponent(props) {
    return (
      <ModalsContext.Consumer>
        {data => <Children {...props} modals={data} />}
      </ModalsContext.Consumer>
    );
  };
}

ModalsProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalsProvider = withTranslation(['common', 'restrict'])(ModalsProviderComponent);

const useModalsContext = () => useContext(ModalsContext);

export { ModalsContext, ModalsProvider, withModals, useModalsContext };
