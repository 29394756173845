import gql from 'graphql-tag';
import { SKILL_TO_VALIDATE_FRAGMENT } from '../skillsToValidate.fragment';

export const GET_SELF_ASSIGNED_SKILL_VALIDATIONS = gql`
  query GET_SELF_ASSIGNED_SKILL_VALIDATIONS(
        $policiesPreset: PoliciesPresetInput 
        $suggestedAction: [UserValidateSkillSuggestedActionFilterEnum] = [empty, add]
  ) {
    userValidateSkills(suggestedAction: $suggestedAction) {
      ${SKILL_TO_VALIDATE_FRAGMENT}
      suggestedAction
      userId
      suggestedBy {
        profile {
          name
        }
      }
    }
  }
`;
