const getRandomInt = (min, max) => min + Math.round(Math.random() * (max - min));

const UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const LOWER = 'abcdefghijklmnopqrstuvwxyz';
const NUMBER = '0123456789';
const ALNUM = `${UPPER}${LOWER}${NUMBER}`;

const getRandomChar = charSource => charSource.charAt(getRandomInt(0, charSource.length - 1));

const getRandomString = (charSource = ALNUM, length = 17) =>
  new Array(length)
    .fill(null)
    .map(() => getRandomChar(charSource))
    .join('');

export { getRandomString };
