import { useGlobalSearchContext } from 'UI/contexts/globalSearch';
import { useEffect, useMemo } from 'react';

const useAutocompleteGlobalSearch = ({
  searchSkillSynonym,
  setSelectSynonym,
  setSearchSkillSynonym,
  setForm,
}) => {
  const globalSearch = useGlobalSearchContext();

  useEffect(() => {
    if (searchSkillSynonym && searchSkillSynonym.trim()) {
      globalSearch.search(searchSkillSynonym, ['SkillSynonym'], {
        bypassCustomerSpecificSynonyms: true,
      });
    }
  }, [searchSkillSynonym]);

  const autoCompleteItems = useMemo(() => {
    const items = globalSearch?.state?.data.filter(
      e => e.companySpecific?.customName === null || e.companySpecific === null
    );
    return [...(items || [])];
  }, [globalSearch.state.data]);

  const onSelect = item => {
    setSelectSynonym({ id: item.synonymId, language: item.synonymLang, name: item.synonymString });
    setForm(prevState => ({ ...prevState, synonymId: item.synonymId }));
  };

  const onClear = () => {
    setSelectSynonym(null);
    setSearchSkillSynonym(null);
    setForm(prevState => ({ ...prevState, synonymId: null }));
  };

  const resetSearch = () => {
    globalSearch.reset();
  };

  return { autoCompleteItems, onSelect, onClear, resetSearch };
};

export default useAutocompleteGlobalSearch;
