import PropTypes from 'prop-types';
import React from 'react';
import { AutoComplete } from '../../../../../../components';

const Search = ({
  id,
  onSearch,
  loading,
  result,
  onSelect,
  placeholder,
  onClear,
  clearInputOnSelect,
}) => (
  <AutoComplete
    id={id}
    placeholder={placeholder}
    items={result}
    onSearch={onSearch}
    delay={150}
    onSelect={onSelect}
    selectedItem={null}
    onClear={onClear}
    showClear
    fullWidth
    loading={loading}
    selectable
    clearInputOnSelect={clearInputOnSelect}
  />
);

Search.propTypes = {
  id: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  result: PropTypes.array,
  loading: PropTypes.bool,
};

Search.defaultProps = {
  placeholder: 'Search',
};

export default Search;
