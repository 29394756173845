import PureLoadable from 'react-17-pure-loadable';
import * as MetricsEmojis from './MetricsEmojis/MetricsEmojis';

export { default as SubMenu } from './SubMenu';
export { default as CompanySpecificSkillLogo } from './CompanySpecificSkillLogo';

const AutoComplete = PureLoadable({
  loader: () => import('./autocomplete/autocomplete.jsx'),
  displayName: 'AutoComplete',
});

const ButtonCheck = PureLoadable({
  loader: () => import('./buttonCheck/buttonCheck.jsx'),
  displayName: 'ButtonCheck',
});

const ButtonCustom = PureLoadable({
  loader: () => import('./buttonCustom/buttonCustom.jsx'),
  displayName: 'ButtonCustom',
});

const ButtonIcon = PureLoadable({
  loader: () => import('./buttonIcon/buttonIcon.jsx'),
  displayName: 'ButtonIcon',
});

const ButtonText = PureLoadable({
  loader: () => import('./buttonText/buttonText'),
  displayName: 'ButtonText',
});

const ChartPie = PureLoadable({
  loader: () => import('./chartPie/chartPie'),
  displayName: 'ChartPie',
});

const CircularPercentage = PureLoadable({
  loader: () => import('./CircularPercentage/CircularPercentage'),
  displayName: 'CircularPercentage',
});

const DialogCustom = PureLoadable({
  loader: () => import('./dialogCustom/dialogCustom'),
  displayName: 'DialogCustom',
});

const Accordion = PureLoadable({
  loader: () => import('./expansionpanel/expansionpanel.jsx'),
  displayName: 'Accordion',
});

const FormField = PureLoadable({
  loader: () => import('./formField/formField.jsx'),
  displayName: 'FormField',
});

const HalfCircularPercentage = PureLoadable({
  loader: () => import('./HalfCircularPercentage/HalfCircularPercentage'),
  displayName: 'HalfCircularPercentage',
});

const ImproveIcon = PureLoadable({
  loader: () => import('./ImproveIcon/ImproveIcon.jsx'),
  displayName: 'ImproveIcon',
});

const InfoTooltip = PureLoadable({
  loader: () => import('./InfoTooltip/InfoTooltip.jsx'),
  displayName: 'InfoTooltip',
});

const LinearChart = PureLoadable({
  loader: () => import('./LinearChart/LinearChart'),
  displayName: 'LinearChart',
});

const LinearProgress = PureLoadable({
  loader: () => import('./linearprogress/linearprogress.jsx'),
  displayName: 'LinearProgress',
});

const LineChartCustom = PureLoadable({
  loader: () => import('./lineChartCustom/lineChartCustom.jsx'),
  displayName: 'LineChartCustom',
});

const LikeCustom = PureLoadable({
  loader: () => import('./likeCustom/likeCustom.jsx'),
  displayName: 'LikeCustom',
});

const PageTitlePanel = PureLoadable({
  loader: () => import('./PageTitlePanel'),
  displayName: 'PageTitlePanel',
});

const DeprecatedPageTitlePanel = PureLoadable({
  loader: () => import('./DeprecatedPageTitlePanel'),
  displayName: 'DeprecatedPageTitlePanel',
});

const Pagination = PureLoadable({
  loader: () => import('./pagination/pagination.jsx'),
  displayName: 'Pagination',
});

const PercentageIndicator = PureLoadable({
  loader: () => import('./percentageIndicator/percentageIndicator'),
  displayName: 'PercentageIndicator',
});

const Stepper = PureLoadable({
  loader: () => import('./stepper/stepper.jsx'),
  displayName: 'Stepper',
});

const TextGroup = PureLoadable({
  loader: () => import('./textGroup/textGroup.jsx'),
  displayName: 'TextGroup',
});

const TooltipCustom = PureLoadable({
  loader: () => import('./tooltipCustom/tooltipCustom.jsx'),
  displayName: 'TooltipCustom',
});

const TrendsArrow = PureLoadable({
  loader: () => import('./trendsArrow/trendsArrow.jsx'),
  displayName: 'TrendsArrow',
});

const Version = PureLoadable({
  loader: () => import('./Version/index.js'),
  displayName: 'Version',
});

const AddSkillSuggestion = PureLoadable({
  loader: () => import('./AddSkillSuggestion/AddSkillSuggestion.jsx'),
  displayName: 'AddSkillSuggestion',
});

const SkillExpertiseManagement = PureLoadable({
  loader: () => import('./SkillExpertiseManagement/SkillExpertiseManagement.jsx'),
  displayName: 'SkillExpertiseManagement',
});

export {
  AutoComplete,
  ButtonCheck,
  ButtonCustom,
  ButtonIcon,
  ButtonText,
  ChartPie,
  DialogCustom,
  CircularPercentage,
  Accordion,
  FormField,
  HalfCircularPercentage,
  ImproveIcon,
  InfoTooltip,
  LikeCustom,
  LinearChart,
  LinearProgress,
  LineChartCustom,
  Pagination,
  PageTitlePanel,
  DeprecatedPageTitlePanel,
  PercentageIndicator,
  Stepper,
  TextGroup,
  TooltipCustom,
  TrendsArrow,
  Version,
  MetricsEmojis,
  AddSkillSuggestion,
  SkillExpertiseManagement,
};
