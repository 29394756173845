import PureLoadable from 'react-17-pure-loadable';
import Container from './Container';

const Header = PureLoadable({
  loader: () => import('./header/header.jsx'),
  displayName: 'Header',
});

const Logo = PureLoadable({
  loader: () => import('./logo/logo.jsx'),
  displayName: 'Logo',
});

const NavRestrict = PureLoadable({
  loader: () => import('./NavRestrict/NavRestrict.jsx'),
  displayName: 'NavRestrict',
});

const SignIn = PureLoadable({
  loader: () => import('./sign_in/sign_in.jsx'),
  displayName: 'SignIn',
});

const LXPUi = PureLoadable({
  loader: () => import('./header/LXPNavigation/LXPNavigation.js'),
  displayName: 'LXPUi',
});

export { Header, Logo, NavRestrict, SignIn, Container, LXPUi };
