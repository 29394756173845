import { Grid, Typography } from '@mui/material';
import { useT } from 'UI/lib';
import SkillExpertise from 'UI/components/SkillExpertise';
import React from 'react';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const StyledTypography = styled(props => (
  <Typography
    sx={{ fontWeight: props.bold ? '600' : 'inherit', color: props.bold ? '#000' : '#616161' }}
  >
    {props.children}
  </Typography>
))``;
const LevelDescriptions = ({ levelDescriptions }) => {
  return Object.entries(levelDescriptions).map(([key, value]) => {
    if (!value || key === '__typename') {
      return null;
    }
    return (
      <Grid container item xs={12} key={`level-desc-${key}`}>
        <Grid item xs={2} sx={{ letterSpacing: 14 }}>
          <SkillExpertise level={key} disableTitleText /> -
        </Grid>
        <Grid item xs>
          <StyledTypography>{value} </StyledTypography>
        </Grid>
      </Grid>
    );
  });
};
const SynonymInfo = ({ synonymInfo }) => {
  const t = useT();

  return (
    <Grid container spacing={10} sx={{ paddingBottom: 8 }}>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold>
            {t('restrict:company_view.customer_synonyms.default.column.custom_name')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>{synonymInfo.customName}</StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold>
            {t('restrict:company_view.customer_synonyms.default.column.name')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>{synonymInfo.name}</StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold>
            {t('restrict:company_view.customer_synonyms.default.column.language')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>{synonymInfo.language}</StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold>
            {t('restrict:company_view.customer_synonyms.default.column.logo')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>
            {synonymInfo.isLogoVisible ? <CheckIcon /> : <CloseIcon />}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold>
            {t('restrict:company_view.customer_synonyms.default.column.created_at')}
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>{synonymInfo.createdAt}</StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold>
            {t('restrict:company_view.customer_synonyms.default.column.description')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>{synonymInfo.description}</StyledTypography>
        </Grid>
      </Grid>
      {synonymInfo.levelDescriptions && (
        <LevelDescriptions levelDescriptions={synonymInfo.levelDescriptions} />
      )}
    </Grid>
  );
};

export default SynonymInfo;
