import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { initializeT } from 'UI/lib';
import SplashScreen from './ui/containers/SplashScreen';
import { DeletedAccount, LoggedOut, Onboarding, Restrict } from './ui/pages';
import Auth from './ui/pages/auth';
import { RootWrapper } from './ui/wrappers';
import RestrictedRoute from './ui/wrappers/restricted-route';
import RestrictedToUnauthenticatedRoute from './ui/wrappers/restricted-to-unauthenticated-route';

const RouterSwitch = ({ t, tReady, location }) => {
  // `tReady` comes from react-i18next library.
  // Once this library is updated, `translate` HoC can be replaced with hooks alternative.
  const [isTInitialized, setIsTInitialized] = useState(false);
  useEffect(() => {
    if (tReady) {
      initializeT(t);
      setIsTInitialized(true);
    }
  }, [tReady, t]);

  if (!isTInitialized) {
    return <SplashScreen />;
  }
  const { search, hash } = location;
  return (
    <RootWrapper>
      <Switch>
        <RestrictedRoute fallbackPath="/onboarding" path="/app" component={Restrict} />
        <RestrictedRoute
          checkAccessPredicate={userDetails => !userDetails.hasAccessToRestrictedPages}
          fallbackPath="/app/overview"
          path="/onboarding"
          component={Onboarding}
        />
        <Route path="/auth" component={Auth} />
        <RestrictedToUnauthenticatedRoute
          fallbackPath="/app"
          path="/logged-out"
          component={LoggedOut}
        />
        <Route path="/account/deleted" component={DeletedAccount} />
        <Redirect
          to={{
            pathname: '/app/overview',
            search,
            hash,
          }}
        />
      </Switch>
    </RootWrapper>
  );
};

export default withTranslation(['common', 'restrict', 'auth', 'notifications_in_app'], {
  usePureComponent: true,
})(withRouter(RouterSwitch));
