import gql from 'graphql-tag';

const GET_COMPANY_SPECIFIC_SKILLS = gql`
  query GET_COMPANY_SPECIFIC_SKILLS {
    companySpecificSkills {
      _id
    }
  }
`;

const GET_SKILLS_SUGGEST_HISTORY = gql`
  query userValidateSkillsSuggestionHistory($page: Int, $perPage: Int, $userId: ID, $skillId: ID) {
    userValidateSkillsSuggestionHistory(
      page: $page
      perPage: $perPage
      userId: $userId
      skillId: $skillId
    ) {
      items {
        _id
        skillId
        userId
        comment
        level
        suggestedAction
        suggestionStatus
        createdAt
        previousLevel
        suggestedBy {
          _id
          profile {
            name
            avatar
          }
        }
      }
      pageInfo {
        pageCount
        itemCount
        currentPage
        perPage
      }
    }
  }
`;

export { GET_COMPANY_SPECIFIC_SKILLS, GET_SKILLS_SUGGEST_HISTORY };
