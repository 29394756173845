import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_SEARCH } from '../queries';
import getApolloClient from '../../getApolloClient';

const GlobalSearchContext = React.createContext();

/* eslint-disable react/no-unused-state */

const defaultState = {
  variables: {
    text: '',
    limit: 10,
    types: ['SkillSynonym', 'JobSynonym'],
  },
  store: {
    loading: false,
    data: [],
  },
};

class GlobalSearchProvider extends Component {
  state = defaultState;

  onSearch = async ({ text, types, options }) => {
    this.setState({ store: { ...this.state.store, loading: true } });

    getApolloClient()
      .query({
        query: GLOBAL_SEARCH,
        variables: {
          ...this.state.variables,
          text,
          types,
          language: localStorage.getItem('i18nextLng'),
          ...options,
        },
      })
      .then(res => {
        let data = [];

        try {
          data = res.data.search;
        } catch (error) {
          data = [];
        }

        this.setState({ store: { loading: false, data } });
      });
  };

  render() {
    const { children } = this.props;
    const { variables, store } = this.state;

    return (
      <GlobalSearchContext.Provider
        value={{
          state: store,
          search: (text, types, options) => this.onSearch({ text, types, options }),
          // deprecated
          setTypes: async types =>
            this.setState({
              variables: Object.assign({}, variables, { types }),
            }),
          reset: () => this.setState(defaultState),
        }}
      >
        {children}
      </GlobalSearchContext.Provider>
    );
  }
}

function withGlobalSearch(Children) {
  return function WrappedComponent(props) {
    return (
      <GlobalSearchContext.Consumer>
        {data => <Children {...props} globalSearch={data} />}
      </GlobalSearchContext.Consumer>
    );
  };
}

GlobalSearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useGlobalSearchContext = () => useContext(GlobalSearchContext);

export { GlobalSearchContext, GlobalSearchProvider, withGlobalSearch };
