import gql from 'graphql-tag';

const ADD_COMPANY_SPECIFIC_SYNONYM = gql`
  mutation insertCustomerSpecificSynonym($record: InsertCompanySpecificSynonymInput) {
    insertCustomerSpecificSynonym(record: $record)
  }
`;

const UPDATE_COMPANY_SPECIFIC_SYNONYM = gql`
  mutation updateCustomerSpecificSynonym($record: UpdateCustomerSpecificSynonymInput, $id: ID!) {
    updateCustomerSpecificSynonym(record: $record, id: $id)
  }
`;

const DELETE_COMPANY_SPECIFIC_SYNONYM = gql`
  mutation deleteCustomerSpecificSynonym($id: ID!) {
    deleteCustomerSpecificSynonym(id: $id)
  }
`;

const IMPORT_CUSTOMER_SPECIFIC_SYNONYM = gql`
  mutation importCustomerSpecificSynonym($file: Upload!) {
    importCustomerSpecificSynonym(file: $file)
  }
`;

export {
  ADD_COMPANY_SPECIFIC_SYNONYM,
  UPDATE_COMPANY_SPECIFIC_SYNONYM,
  DELETE_COMPANY_SPECIFIC_SYNONYM,
  IMPORT_CUSTOMER_SPECIFIC_SYNONYM,
};
