import React, { useContext } from 'react';
import get from 'lodash/get';
import useConfig from 'UI/customHooks/useConfig';
import { useAddSkillsToValidate } from '../containers/SkillValidation/SelfAssignedSkills/useAddSkillsToValidate';

const UserValidateSkillsContext = React.createContext();

const UserSkillsToValidate = ({ children }) => {
  const config = useConfig();
  const skillSynonymPoliciesPreset = get(
    config,
    'cms.components.SelfAssignedSkills.skillSynonymPoliciesPreset'
  );
  const { addSkillsToValidate } = useAddSkillsToValidate({ skillSynonymPoliciesPreset });

  return (
    <UserValidateSkillsContext.Provider value={{ createMany: addSkillsToValidate }}>
      {children}
    </UserValidateSkillsContext.Provider>
  );
};

const useUserValidateSkills = () => useContext(UserValidateSkillsContext);

const withUserValidateSkills = Component => props => {
  const data = useUserValidateSkills();
  return <Component {...props} userValidateSkills={data} />;
};

export {
  UserValidateSkillsContext,
  UserSkillsToValidate as UserValidateSkillsProvider,
  withUserValidateSkills,
  useUserValidateSkills,
};
