import React, { useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { clientLogger } from 'UI/lib';
import useConfig from 'UI/customHooks/useConfig';
import styles from './styles';

const SplashScreen = ({ classes, error }) => {
  const { headerLogoUrl, loaderImage, isConfigLoading, splashLogoStyle } = PALYX_WHITE_LABEL;

  const hasLogoUrl = !isConfigLoading && (headerLogoUrl || loaderImage);
  const { rootWidth, progressBarMarginTop } = splashLogoStyle ? JSON.parse(splashLogoStyle) : {};
  const { cdnUrl } = useConfig();

  const handleLogo = logo => {
    if (logo.startsWith('http')) {
      return logo;
    }
    return `${cdnUrl}${headerLogoUrl}`;
  };

  useEffect(() => {
    if (error) {
      clientLogger.error(error);
    }
  }, [error]);

  return (
    <div key="splash-screen">
      <div className={classes.root} style={{ width: rootWidth }}>
        {hasLogoUrl && (
          <img src={handleLogo(loaderImage || headerLogoUrl)} alt="logo" width="100%" />
        )}
        <div className={classes.progressWrapper} style={{ marginTop: progressBarMarginTop }}>
          <LinearProgress classes={{ root: classes.progress }} />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SplashScreen);
