import gql from 'graphql-tag';
import { CAPABILITY_SKILLS_FIELDS } from '../queries/organizationCapabilities';

const ORGANIZATION_CAPABILITY_CREATE = gql`
  mutation ORGANIZATION_CAPABILITY_CREATE($record: CreateOneOrganizationCapabilityInput!) {
    organizationCapabilityCreateOne(record: $record) {
      __typename
      recordId
      record {
        __typename
        _id
        companyId
        name
        organizationId
        deleted
        description
        ownerUserId
        createdAt
        updatedAt
        type
        selfDefined
        accessRights
        editRights
        location
        levelOfEmployeeMin
        levelOfEmployeeMax
        jobLevel
        published
        publishedAt
        deletedAt
        lastEditedBy
        edited
        customLink
        editable
      }
    }
  }
`;

const ORGANIZATION_CAPABILITY_UPDATE = gql`
  mutation ORGANIZATION_CAPABILITY_UPDATE($record: UpdateByIdOrganizationCapabilityInput!) {
    organizationCapabilityUpdateById(record: $record) {
      __typename
      recordId
      record {
        __typename
        _id
        companyId
        name
        organizationId
        deleted
        description
        ownerUserId
        createdAt
        updatedAt
        type
        selfDefined
        accessRights
        editRights
        location
        levelOfEmployeeMin
        levelOfEmployeeMax
        jobLevel
        published
        publishedAt
        deletedAt
        lastEditedBy
        edited
        customLink
        editable
        contactEmail
      }
    }
  }
`;

const ORGANIZATION_CAPABILITY_DELETE = gql`
  mutation ORGANIZATION_CAPABILITY_DELETE($_id: ID!) {
    organizationCapabilityRemoveById(_id: $_id) {
      __typename
      recordId
      record {
        __typename
        _id
        companyId
        name
        organizationId
        deleted
        description
        ownerUserId
        createdAt
        updatedAt
        type
        selfDefined
        accessRights
        editRights
        location
        levelOfEmployeeMin
        levelOfEmployeeMax
        jobLevel
        published
        publishedAt
        deletedAt
        lastEditedBy
        edited
        customLink
        editable
      }
    }
  }
`;

const CAPABILITY_SKILLS_CREATE = gql`
  mutation CAPABILITY_SKILLS_CREATE(
    $record: [CreateOneCapabilitySkillInput]!
    $policiesPreset: PoliciesPresetInput
  ) {
    capabilitySkillCreateMany(record: $record) {
      __typename
      recordId
      record {
        ${CAPABILITY_SKILLS_FIELDS}
      }
    }
  }
`;

const CAPABILITY_SKILL_UPDATE = gql`
  mutation CAPABILITY_SKILL_UPDATE(
    $record: UpdateByIdCapabilitySkillInput!
    $policiesPreset: PoliciesPresetInput
  ) {
    capabilitySkillUpdateById(record: $record) {
      __typename
      recordId
      record {
        ${CAPABILITY_SKILLS_FIELDS}
      }
    }
  }
`;

const CAPABILITY_SKILL_DELETE = gql`
  mutation CAPABILITY_SKILL_DELETE($_id: ID!) {
    capabilitySkillRemoveById(_id: $_id) {
      __typename
      recordId
    }
  }
`;

export {
  ORGANIZATION_CAPABILITY_CREATE,
  ORGANIZATION_CAPABILITY_UPDATE,
  ORGANIZATION_CAPABILITY_DELETE,
  CAPABILITY_SKILLS_CREATE,
  CAPABILITY_SKILL_UPDATE,
  CAPABILITY_SKILL_DELETE,
};
