import get from 'lodash/get';
import useConfig from 'UI/customHooks/useConfig';

const availableLanguages = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const config = useConfig();
  const languages = config?.cms?.features?.localization?.languages;
  const availableLanguages =
    languages &&
    Object.keys(languages)
      .map(item => languages[item])
      .filter(item => item.isActive);
  const depricatedAvailableLanguages = get(
    config,
    'cms.features.localization.availableLanguages',
    []
  );
  return depricatedAvailableLanguages || availableLanguages;
};

export default availableLanguages;
