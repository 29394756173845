import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { clientLogger } from 'UI/lib';
import { GET_VIEWER_SKILLS } from './getViewerSkills.query';

const useViewerSkills = ({ skillSynonymPoliciesPreset, skip }) => {
  const { data, loading, error, refetch } = useQuery(GET_VIEWER_SKILLS, {
    variables: {
      policiesPreset: { preset: skillSynonymPoliciesPreset },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: skip,
  });

  useEffect(() => {
    if (error) {
      clientLogger.error(`useViewerSkills - ${error.message}`, error);
    }
  }, [error]);

  const viewerSkillsWithSynonyns = useMemo(
    () => data?.viewerSkills?.filter(userSkill => userSkill.skill?.synonym),
    [data]
  );

  return {
    skills: viewerSkillsWithSynonyns,
    isLoading: loading,
    hasError: Boolean(error),
    refetch,
  };
};

export default useViewerSkills;
