import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useT, clientLogger } from 'UI/lib';
import { useMessageContext } from '../../../../../../contexts';
import { TIMELINE_PERSON_MEASURE_FRAGMENT } from '../../usePersonMeasures';

const ADD_MANUAL_MEASURE_TO_PERSONAL_PLAN = gql`
  mutation addManualMeasureToPersonalDevelopment(
    $data: AddManualMeasureToPersonalDevelopmentDataInput!
  ) {
    addManualMeasureToPersonalDevelopment(data: $data)
  }
`;

const UPDATE_MANUAL_MEASURE_IN_PERSONAL_PLAN = gql`
  mutation updateManualMeasureInPersonalDevelopment(
    $measureId: ID!
    $data: UpdateManualMeasureInPersonalDevelopmentDataInput!
    $targetUserId: String
    $policiesPreset: PoliciesPresetInput
  ) {
    updateManualMeasureInPersonalDevelopment(
      measureId: $measureId
      data: $data
      targetUserId: $targetUserId
    ) {
      id
      ...timelinePersonMeasureFragment
    }
  }
  ${TIMELINE_PERSON_MEASURE_FRAGMENT}
`;

const useManualCreation = ({ measureId, form, onFinish }) => {
  const t = useT();
  const message = useMessageContext();

  const [addManualMeasureToPersonalDevelopment] = useMutation(ADD_MANUAL_MEASURE_TO_PERSONAL_PLAN);
  const [updateManualMeasureInPersonalDevelopment] = useMutation(
    UPDATE_MANUAL_MEASURE_IN_PERSONAL_PLAN
  );

  const addManuallyAddedMeasureToPersonalDevelopment = useCallback(
    async (data, skills) => {
      const inputArgs = {
        ...data,
        startedAt: new Date(form.startedAt),
        endedAt: form.endedAt ? new Date(form.endedAt) : null,
        skillSynonymIds: skills.map(({ synonym }) => synonym.synonymId),
      };

      try {
        await addManualMeasureToPersonalDevelopment({
          variables: {
            data: inputArgs,
          },
          update: cache => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonMeasures' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonImprovedSkills' });
            cache.gc();
          },
        });
        message.setMessage({
          text: t('restrict:personal-development.measure_added'),
          type: 'success',
        });
      } catch (error) {
        clientLogger.error(error);
        message.setMessage({
          text: t('common:default_error_message'),
          type: 'error',
        });
      }
      onFinish();
    },
    [t, form, onFinish, addManualMeasureToPersonalDevelopment, message]
  );

  const updateManuallyAddedMeasureInPersonalDevelopment = useCallback(
    async (data, skills, targetUserId) => {
      const inputArgs = {
        ...data,
        startedAt: new Date(form.startedAt),
        endedAt: form.endedAt ? new Date(form.endedAt) : null,
        skillSynonymIds: skills.map(skill => skill.synonym.synonymId),
      };

      try {
        await updateManualMeasureInPersonalDevelopment({
          variables: {
            measureId,
            data: inputArgs,
            targetUserId,
          },
          update: cache => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonImprovedSkills' });
            cache.gc();
          },
        });

        message.setMessage({
          text: t('restrict:personal-development.measure_updated'),
          type: 'success',
        });
      } catch (error) {
        clientLogger.error(error);
        message.setMessage({
          text: t('common:default_error_message'),
          type: 'error',
        });
      }
      onFinish();
    },
    [t, onFinish, form, measureId, updateManualMeasureInPersonalDevelopment, message]
  );

  return {
    addManuallyAddedMeasureToPersonalDevelopment,
    updateManuallyAddedMeasureInPersonalDevelopment,
  };
};

export default useManualCreation;
