const UsersSelectStyles = theme => ({
  fullName: {
    fontSize: 18,
    color: theme.palette.custom.secondary,
  },
  formControl: {
    minWidth: 115,
  },
});

export default UsersSelectStyles;
