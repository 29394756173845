import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useT, clientLogger } from 'UI/lib';
import { useMessageContext } from '../../../../../../contexts';
import { TIMELINE_PERSON_MEASURE_FRAGMENT } from '../../usePersonMeasures';

const ADD_CHECKIN_MEASURE_TO_PERSONAL_PLAN = gql`
  mutation addCheckInToPersonalDevelopment($data: AddCheckInToPersonalDevelopmentDataInput!) {
    addCheckInToPersonalDevelopment(data: $data)
  }
`;

const UPDATE_CHECKIN_MEASURE_IN_PERSONAL_PLAN = gql`
  mutation updateCheckInMeasureInPersonalDevelopment(
    $measureId: ID!
    $data: UpdateCheckInMeasureInPersonalDevelopmentDataInput!
    $targetUserId: String
    $policiesPreset: PoliciesPresetInput
  ) {
    updateCheckInMeasureInPersonalDevelopment(
      measureId: $measureId
      data: $data
      targetUserId: $targetUserId
    ) {
      id
      ...timelinePersonMeasureFragment
    }
  }
  ${TIMELINE_PERSON_MEASURE_FRAGMENT}
`;

const useCheckIn = ({ onFinish, measureId, targetUserId }) => {
  const t = useT();
  const message = useMessageContext();

  const [addCheckInToPersonalDevelopment] = useMutation(ADD_CHECKIN_MEASURE_TO_PERSONAL_PLAN);
  const [updateCheckInMeasureInPersonalDevelopment] = useMutation(
    UPDATE_CHECKIN_MEASURE_IN_PERSONAL_PLAN
  );

  const addCheckinToPersonalDevelopment = useCallback(
    async data => {
      try {
        await addCheckInToPersonalDevelopment({
          variables: {
            data,
          },
          update: cache => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonMeasures' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonImprovedSkills' });
            cache.gc();
          },
        });

        message.setMessage({
          text: t('restrict:personal-development.measure_added'),
          type: 'success',
        });
      } catch (error) {
        clientLogger.error(error);
        message.setMessage({
          text: t('common:default_error_message'),
          type: 'error',
        });
      }
      onFinish();
    },
    [t, onFinish, addCheckInToPersonalDevelopment, message]
  );

  const updateCheckinInPersonalDevelopment = useCallback(
    async data => {
      try {
        await updateCheckInMeasureInPersonalDevelopment({
          variables: {
            measureId,
            data,
            targetUserId,
          },
          update: cache => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonMeasures' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPersonImprovedSkills' });
            cache.gc();
          },
        });

        message.setMessage({
          text: t('restrict:personal-development.measure_updated'),
          type: 'success',
        });
      } catch (error) {
        clientLogger.error(error);
        message.setMessage({
          text: t('common:default_error_message'),
          type: 'error',
        });
      }
      onFinish();
    },
    [t, onFinish, measureId, updateCheckInMeasureInPersonalDevelopment, targetUserId, message]
  );

  return {
    addCheckinToPersonalDevelopment,
    updateCheckinInPersonalDevelopment,
  };
};

export default useCheckIn;
