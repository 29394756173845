import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useT } from 'UI/lib';
import React, { useCallback, useEffect, useState } from 'react';
import { useCheckInDiscussionPartners } from '../useCheckInDiscussionPartners';
import styles from './UsersSelect.styles';

const useStyles = makeStyles(styles);

const UsersSelect = ({ value, isViewOnly, onValueChange }) => {
  const t = useT();

  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [selectedUser, setSelectedUser] = useState(
    value ? { id: value._id, name: value.profile.name } : null
  );

  useEffect(() => {
    if (onValueChange) {
      onValueChange('discussionPartnerId', selectedUser ? selectedUser.id.split('.')[0] : '');
    }
  }, [onValueChange, selectedUser]);

  const { users, isLoading, hasError } = useCheckInDiscussionPartners({
    searchText,
  });

  const handleSearch = useCallback(event => {
    if (event) {
      setSearchText(event.target.value);
    }
  }, []);

  const onInputValueChange = useCallback(user => setSelectedUser(user), []);

  return (
    <>
      {hasError && t('common:default_error_message')}
      {!isViewOnly && (
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            {...{
              htmlFor: 'discussion-partner',
            }}
            data-test="check-in__discussion-partner-input"
            shrink
          />
          <Autocomplete
            id="discussion-partner"
            value={selectedUser}
            options={users || []}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder={t('restrict:company_view.search_person')}
              />
            )}
            onInputChange={handleSearch}
            onChange={(event, user) => onInputValueChange(user)}
            loading={isLoading}
            getOptionLabel={user => `${user.name} (${user.email})`}
            itemProp={{
              sx: { whiteSpace: 'pre-line' },
            }}
          />
        </FormControl>
      )}
      {isViewOnly && <div className={classes.fullName}>{value.profile.name}</div>}
    </>
  );
};

export default UsersSelect;
