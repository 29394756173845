import gql from 'graphql-tag';
import { USER_SKILL_FRAGMENT } from '../pages/restrict/skills/SkillsTab/getViewerSkills.query';

const USER_SKILL_CREATE = gql`
  mutation USER_SKILL_CREATE($record: CreateOneUserSkillInput!, $policiesPreset: PoliciesPresetInput) {
    userSkillCreateOne(record: $record) {
      __typename
      recordId
      record {
        ${USER_SKILL_FRAGMENT}
      }
    }
  }
`;

const USER_SKILLS_CREATE = gql`
  mutation USER_SKILLS_CREATE($record: [CreateOneUserSkillInput]!, $policiesPreset: PoliciesPresetInput) {
    userSkillCreateMany(record: $record) {
      __typename
      recordId
      record {
        ${USER_SKILL_FRAGMENT}
      }
    }
  }
`;

const USER_SKILL_UPDATE = gql`
  mutation USER_SKILL_UPDATE($record: UpdateByIdUserSkillInput!, $policiesPreset: PoliciesPresetInput) {
    userSkillUpdateById(record: $record) {
      __typename
      recordId
      record {
        ${USER_SKILL_FRAGMENT}
      }
    }
  }
`;

const USER_SKILLS_UPDATE = gql`
  mutation USER_SKILLS_UPDATE($records: [UpdateManyUserSkillInput]!, $policiesPreset: PoliciesPresetInput) {
    userSkillsUpdateMany(records: $records) {
      ${USER_SKILL_FRAGMENT}
    }
  }
`;

const USER_SKILL_DELETE = gql`
  mutation USER_SKILL_DELETE($skillId: ID!) {
    userSkillRemoveById(skillId: $skillId)
  }
`;

const PERSON_SKILLS_PROFILE_EXPORT = gql`
  mutation PERSON_SKILLS_PROFILE_EXPORT(
    $sort: String
    $timeZone: String!
    $synonymPolicies: [SkillSynonymPolicyInput!]
  ) {
    personSkillsProfileExport(sort: $sort, timeZone: $timeZone, synonymPolicies: $synonymPolicies)
  }
`;

const MANAGER_SUGGESTION_USER_SKILL = gql`
  mutation userValidateSkillSuggestMany(
    $records: [CreateUserValidateSkillSuggestionInput!]!
    $userId: ID!
  ) {
    userValidateSkillSuggestMany(records: $records, userId: $userId) {
      record {
        expertise
        suggestedBy {
          _id
          profile {
            name
          }
        }
        suggestedAction
      }
    }
  }
`;

const MANAGER_DELETE_SUGGESTION = gql`
  mutation userValidateSkillRemoveBySkillIdsByManager($skillIds: [ID!]!, $userId: ID!) {
    userValidateSkillRemoveBySkillIdsByManager(skillIds: $skillIds, userId: $userId)
  }
`;

export {
  USER_SKILL_CREATE,
  USER_SKILLS_CREATE,
  USER_SKILL_UPDATE,
  USER_SKILLS_UPDATE,
  USER_SKILL_DELETE,
  PERSON_SKILLS_PROFILE_EXPORT,
  MANAGER_SUGGESTION_USER_SKILL,
  MANAGER_DELETE_SUGGESTION,
};
