import { navigateInternal } from 'UI/lib';
import getBackend from '../getBackend';
import getIsRestrictArea from './getIsRestrictArea';
import {
  getBrowserUserCredentials,
  setBrowserUserCredentials,
  logoutUser,
} from './userCredentials';
import { AUTH_DEFAULT_LOGIN_REDIRECT_URL, AUTH_ERROR_CODE_QUERY_PARAM } from './constants';

/** Gets and persists user credentials into localStorage using SSO search params.
 * @typedef {Object} UserCredentials
 * @property {string} userId
 * @property {string} loginToken
 */

/**
 * @return {Promise.<{ ok: boolean, status: number, credentials: UserCredentials}>}
 */
const fetchCredentials = async (reqUrl, body) => {
  const response = await fetch(reqUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const { status } = response;

  if (status === 200) {
    const { access_token, relay_state, sub } = await response.json();

    return {
      ok: true,
      credentials: {
        userId: sub,
        accessToken: access_token,
        relayState: relay_state,
      },
    };
  }

  return {
    ok: false,
    status,
  };
};

/** Gets and persists user credentials into localStorage using SSO search params.
 * @return {Promise.<void>}
 */
export const loginSsoUser = async () => {
  const currentUrl = new URL(window.location.href);

  if (getIsRestrictArea(currentUrl)) {
    return;
  }

  const { palyxToken, palyxUserId } = getBrowserUserCredentials();
  const isUserLogged = Boolean(palyxToken && palyxUserId);

  if (isUserLogged) {
    return;
  }

  const BACKENDURL = getBackend();

  const code = currentUrl.searchParams.get('code');
  const state =
    currentUrl.searchParams.get('state') || encodeURIComponent(AUTH_DEFAULT_LOGIN_REDIRECT_URL);

  const isSso = Boolean(code?.trim());

  if (isSso) {
    const reqUrl = `${BACKENDURL}/auth/sso/login`;
    const response = await fetchCredentials(reqUrl, { code, state });

    if (response.ok) {
      const { userId: palyxUserId, accessToken: palyxToken } = response.credentials;

      setBrowserUserCredentials({
        palyxUserId,
        palyxToken,
      });
      navigateInternal(decodeURIComponent(state));
      return;
    }

    await logoutUser(`/?${AUTH_ERROR_CODE_QUERY_PARAM}=${response.status || 500}`);
    return;
  }
};
