import PureLoadable from 'react-17-pure-loadable';

const AddSkillsManually = PureLoadable({
  loader: () => import('./addSkillsManually/addSkillsManually.jsx'),
  displayName: 'AddSkillsManually',
});

const DropZone = PureLoadable({
  loader: () => import('./dropZone/dropZone.jsx'),
  displayName: 'DropZone',
});

const CapabilityScanDocument = PureLoadable({
  loader: () => import('./capaScanDocument/CapabilityScanDocument.jsx'),
  displayName: 'CapaScanDocument',
});

const CapaSkillsByJob = PureLoadable({
  loader: () => import('./capaSkillsByJob/capaSkillsByJob.jsx'),
  displayName: 'CapaSkillsByJob',
});

const JobAds = PureLoadable({
  loader: () => import('./jobAds/jobAds.jsx'),
  displayName: 'JobAds',
});

const JobSkillsSuggestions = PureLoadable({
  loader: () => import('./jobSkillsSuggestions/jobSkillsSuggestions.jsx'),
  displayName: 'JobSkillsSuggestions',
});

const MobilityCalculator = PureLoadable({
  loader: () => import('./MobilityCalculator'),
  displayName: 'MobilityCalculator',
});

const NewsDetails = PureLoadable({
  loader: () => import('./newsDetails/newsDetails.jsx'),
  displayName: 'NewsDetails',
});

const PrivacyPolicy = PureLoadable({
  loader: () => import('./privacyPolicy/privacyPolicy.jsx'),
  displayName: 'PrivacyPolicy',
});

const RefineSkills = PureLoadable({
  loader: () => import('./RefineSkills/RefineSkills.jsx'),
  displayName: 'RefineSkills',
});

const RefineSkillsByJob = PureLoadable({
  loader: () => import('./refineSkillsByJob/refineSkillsByJob.jsx'),
  displayName: 'RefineSkillsByJob',
});

const ScanDocument = PureLoadable({
  loader: () => import('./ScanDocument/ScanDocument.jsx'),
  displayName: 'ScanDocument',
});

const TermsAndConditions = PureLoadable({
  loader: () => import('./termsAndConditions/termsAndConditions.jsx'),
  displayName: 'TermsAndConditions',
});

const SkillsSelectPanel = PureLoadable({
  loader: () => import('./SkillsSelectPanel'),
  displayName: 'SkillsSelectPanel',
});

const ExplainVideo = PureLoadable({
  loader: () => import('./explainVideo/explainVideo'),
  displayName: 'ExplainVideo',
});

const ExplainFeatureVideo = PureLoadable({
  loader: () => import('./ExplainFeatureVideo/ExplainFeatureVideo'),
  displayName: 'ExplainFeatureVideo',
});

const CapabilitySkillsSuggestions = PureLoadable({
  loader: () => import('./CapabilitySkillsSuggestions'),
  displayName: 'CapabilitySkillsSuggestions',
});

const SearchAndAddCompanySpecificSkills = PureLoadable({
  loader: () => import('./SearchAndAddCompanySpecificSkills'),
  displayName: 'SearchAndAddCompanySpecificSkills',
});

export {
  AddSkillsManually,
  CapabilityScanDocument,
  CapaSkillsByJob,
  DropZone,
  ExplainVideo,
  ExplainFeatureVideo,
  JobAds,
  JobSkillsSuggestions,
  MobilityCalculator,
  NewsDetails,
  PrivacyPolicy,
  RefineSkills,
  RefineSkillsByJob,
  ScanDocument,
  TermsAndConditions,
  SkillsSelectPanel,
  CapabilitySkillsSuggestions,
  SearchAndAddCompanySpecificSkills,
};
