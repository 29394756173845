import intersection from 'lodash/intersection';
import { clientLogger } from 'UI/lib';
import { OpportunitySkillFields } from './OpportunitySkillFields.fragment';
import { MarketOpportunityFields } from './MarketOpportunities/MarketOpportunityFields.fragment';
import { CompanyOpportunityFields } from './CompanyOpportunities/CompanyOpportunityFields.fragment';

const MARKET_OPPORTUNITY = 'MarketOpportunity';
const COMPANY_OPPORTUNITY = 'CompanyOpportunity';

const buildSkill2SkillSearch = store => {
  const temporaryCache = {};
  return id => {
    if (!temporaryCache[id]) {
      const opportunitySkill = store.readFragment({
        fragment: OpportunitySkillFields,
        id,
      });

      temporaryCache[id] = opportunitySkill?.skillId;
    }

    return temporaryCache[id];
  };
};

const getIsMarketOpportunity = key => key.startsWith(MARKET_OPPORTUNITY);
const getIsCompanyOpportunity = key => key.startsWith(COMPANY_OPPORTUNITY);

const getIdForOpportunity = (store, fragment, opportunityKey) => {
  const opportunity = store.readFragment({
    fragment,
    fragmentName: fragment.mainFragmentName,
    id: opportunityKey,
  });
  return opportunity?._id;
};

const getIdForCompanyOpportunity = (store, key) =>
  getIdForOpportunity(store, CompanyOpportunityFields, key);

const getIdForMarketOpportunity = (store, key) =>
  getIdForOpportunity(store, MarketOpportunityFields, key);

const extractCachedEntriesToUpdate = (addedSkillIds, { store, getSkillIdFromOppSkillId }) => {
  const allCacheEntries = store.extract();
  const cacheEntryKeys = allCacheEntries ? Object.keys(allCacheEntries) : [];

  if (!cacheEntryKeys.length) {
    return [];
  }
  const cacheKeys = cacheEntryKeys.filter(
    key => getIsMarketOpportunity(key) || getIsCompanyOpportunity(key)
  );

  const opportunityIdsToUpdate = cacheKeys.filter(key => {
    const { mostRelevant = [], relevant = [] } = allCacheEntries[key]?.missing || {};

    const mostRelevantSkillIds = mostRelevant.map(({ __ref: id }) => getSkillIdFromOppSkillId(id));
    const relevantSkillIds = relevant.map(({ __ref: id }) => getSkillIdFromOppSkillId(id));

    const includesAddedSkill =
      intersection(mostRelevantSkillIds, addedSkillIds).length ||
      intersection(relevantSkillIds, addedSkillIds).length;
    return includesAddedSkill;
  });

  const entriesToUpdate = opportunityIdsToUpdate.map(key => {
    if (getIsMarketOpportunity(key)) {
      return {
        type: MARKET_OPPORTUNITY,
        opportunityId: getIdForMarketOpportunity(store, key),
      };
    }
    return {
      type: COMPANY_OPPORTUNITY,
      opportunityId: getIdForCompanyOpportunity(store, key),
    };
  });

  return entriesToUpdate;
};

// This method removes skills from missing and add to matching ones
export const updateSkillsInCachedOpportunities = (store, addedSkillIds = []) => {
  try {
    const getSkillIdFromOppSkillId = buildSkill2SkillSearch(store);
    const cachedEntriesToUpdate = extractCachedEntriesToUpdate(addedSkillIds, {
      store,
      getSkillIdFromOppSkillId,
    });

    if (!cachedEntriesToUpdate.length) {
      return;
    }

    cachedEntriesToUpdate.forEach(({ type, opportunityId }) => {
      const fragment =
        type === MARKET_OPPORTUNITY ? MarketOpportunityFields : CompanyOpportunityFields;
      const cachedKey = `${type}:${opportunityId}`;

      const cachedOpportunity = store.readFragment({
        fragmentName: fragment.mainFragmentName,
        fragment,
        id: cachedKey,
      });

      if (!cachedOpportunity) {
        return;
      }

      const { matching, missing } = cachedOpportunity;

      if (!missing) {
        return;
      }

      const updatedData = {
        __typename: type,
        ...cachedOpportunity,
        missing: {
          ...cachedOpportunity.missing,
          mostRelevant: missing.mostRelevant.filter(
            ({ skillId }) => !addedSkillIds.includes(skillId)
          ),
          relevant: missing.relevant.filter(({ skillId }) => !addedSkillIds.includes(skillId)),
        },
        matching: {
          ...cachedOpportunity.matching,
          mostRelevant: [
            ...addedSkillIds
              .map(addedSkillId => {
                const oldMissingEntry =
                  missing.relevant.find(({ skillId }) => skillId === addedSkillId) ||
                  missing.mostRelevant.find(({ skillId }) => skillId === addedSkillId);
                return oldMissingEntry;
              })
              .filter(Boolean),
            ...matching.mostRelevant,
          ],
        },
      };

      store.writeFragment({
        id: cachedKey,
        fragment,
        fragmentName: fragment.mainFragmentName,
        data: updatedData,
      });
    });
  } catch (error) {
    clientLogger.error(error);
  }
};
