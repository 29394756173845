import PureLoadable from 'react-17-pure-loadable';
import SplashScreen from '../../containers/SplashScreen';

const Auth = PureLoadable({
  loader: () => import('./Auth'),
  loading: SplashScreen,
  displayName: 'Auth',
});

export default Auth;
