import PureLoadable from 'react-17-pure-loadable';
import SplashScreen from '../containers/SplashScreen';
import DeletedAccount from './DeletedAccount';
import LoggedOut from './LoggedOut/LoggedOut';

const Onboarding = PureLoadable({
  loader: () => import('./onboarding/onboarding'),
  loading: SplashScreen,
  displayName: 'Onboarding',
});

const Page404 = PureLoadable({
  loader: () => import('./404/404'),
  displayName: 'Page404',
});

const Restrict = PureLoadable({
  loader: () => import('./restrict/restrict'),
  loading: SplashScreen,
  displayName: 'Restrict',
});

export { Onboarding, Page404, Restrict, LoggedOut, DeletedAccount };
