const getOrganizationIdKeyEncr = userId => {
  if (!userId) {
    return null;
  }
  return `currentOrganization_${userId}`;
};

export const getSelectedOrganizationIdEncr = userId => {
  const organizationIdKey = getOrganizationIdKeyEncr(userId);
  if (!organizationIdKey) {
    return null;
  }
  return localStorage.getItem(organizationIdKey);
};

export const setSelectedOrganizationIdEncr = (organizationId, userId) => {
  if (!organizationId || organizationId === 'null') {
    return false;
  }
  const organizationIdKey = getOrganizationIdKeyEncr(userId);
  if (!organizationIdKey) {
    return false;
  }
  localStorage.setItem(organizationIdKey, organizationId);
  return true;
};

const getOrganizationIdKey = () => {
  const userId = localStorage.getItem('Meteor.userId');
  if (!userId) {
    return null;
  }
  return `currentOrganization_${userId}`;
};

export const getSelectedOrganizationId = () => {
  const organizationIdKey = getOrganizationIdKey();
  if (!organizationIdKey) {
    return null;
  }
  return localStorage.getItem(organizationIdKey);
};

export const setSelectedOrganizationId = organizationId => {
  if (!organizationId || organizationId === 'null') {
    return false;
  }
  const organizationIdKey = getOrganizationIdKey();
  if (!organizationIdKey) {
    return false;
  }
  localStorage.setItem(organizationIdKey, organizationId);
  return true;
};
