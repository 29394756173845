import CancelCircleSvg from 'UI/pages/restrict/skills/SkillsTab/SkillsTable/utils/CancelCircleSvg';
import CheckCircleSvg from 'UI/pages/restrict/skills/SkillsTab/SkillsTable/utils/checkCircleSvg';
import React from 'react';
import PendingIconSvg from 'UI/pages/restrict/skills/SkillsTab/SkillsTable/utils/PendingIconSvg';

const StatusIcons = primaryColor => {
  return {
    rejected: <CancelCircleSvg />,
    confirmed: <CheckCircleSvg />,
    pending: <PendingIconSvg fill={primaryColor} />,
  };
};

export default StatusIcons;
