import { useMutation } from '@apollo/client';
import { IMPORT_CUSTOMER_SPECIFIC_SYNONYM } from '../graphql/mutations';
import { useCallback } from 'react';

const useImportCustomerSynonyms = () => {
  const [importCompanySynonyms, { error, loading }] = useMutation(IMPORT_CUSTOMER_SPECIFIC_SYNONYM);

  const useImportCustomerSpecificSynonyms = useCallback(
    async file => {
      await importCompanySynonyms({
        variables: { file },
        update: cache => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'customerSpecificSynonyms' });
        },
      });
    },
    [importCompanySynonyms]
  );

  return { importCompanySynonyms: useImportCustomerSpecificSynonyms, isLoading: loading, error };
};

export default useImportCustomerSynonyms;
