import React, { useCallback } from 'react';

const tContainer = {
  t: key => key,
};

const initializeT = translationFunction => {
  tContainer.t = translationFunction;
};

const useT = () => useCallback(tContainer.t);

const withT = Component => ({ children, ...rest }) => (
  <Component {...rest} t={tContainer.t}>
    {children}
  </Component>
);

export { initializeT, useT, withT };
