import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import get from 'lodash/get';
import queryString from 'query-string';
import React, { useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ButtonCustom } from '../../components';
import FullWidthContainer from '../../components/FullWidthContainer';
import { MainLayout } from '../../layouts';
import { wrapComponent } from '../../lib/compose-wrappers';
import IdlenessNotificationModal from '../../wrappers/auth-wrapper/IdlenessNotificationModal';
import styles from './LoggedOut.styles';

const LoggedOut = ({ classes, t, history, location }) => {
  const onRedirectToLogin = useCallback(() => {
    history.push('/auth/login');
  }, [history]);

  const wasLoggingOutForced = useMemo(() => {
    const locationSearchParsed = queryString.parse(location.search);
    return get(locationSearchParsed, 'sessionExpired', false);
  }, [location.search]);

  return (
    <MainLayout>
      <div className={classes.rootWrapper}>
        <FullWidthContainer>
          <div>
            <Typography className={classes.h1} variant="h1">
              {t('auth:loggedout_header')}
            </Typography>
            <div className={classes.textWrapper}>
              {t('auth:loggedout_text')
                .split('\n')
                .map(line => (
                  <Typography key={line} className={classes.h4} variant="h4">
                    {line}
                  </Typography>
                ))}
            </div>
            <ButtonCustom className={classes.cta} color="primary" onClick={onRedirectToLogin}>
              {t('auth:loggedout_cta')}
            </ButtonCustom>
          </div>
        </FullWidthContainer>
        {wasLoggingOutForced && <IdlenessNotificationModal />}
      </div>
    </MainLayout>
  );
};

export default wrapComponent(LoggedOut, [withTranslation('auth'), withStyles(styles), withRouter]);
