import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import Countdown from 'react-countdown-now';
import { MainLayout } from '../../layouts';
import useConfig from 'UI/customHooks/useConfig';
import Container from '../../layouts/components/Container';
import styles from './styles';

const DeletedAccount = ({ classes, t }) => {
  const config = useConfig();

  const deleteAccountRedirectUrl = get(
    config,
    'cms.pages.myProfile.deleteAccountRedirectUrl',
    false
  );

  return (
    <MainLayout>
      <div className={classes.rootWrapper}>
        <Container className={classes.root}>
          <div style={{ maxWidth: 770 }}>
            <Typography className={classes.h1}>{t('auth:deleted_account_header')}</Typography>

            {deleteAccountRedirectUrl && (
              <div className={classes.redirectionMessage}>
                {t('auth:delete_account_redirect_message')}
                <Countdown
                  date={Date.now() + 5000}
                  onComplete={() => {
                    window.location.href = deleteAccountRedirectUrl;
                  }}
                  renderer={renderCountDown}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </MainLayout>
  );
};

const renderCountDown = ({ seconds }) => <span> {seconds}s</span>;

export default withTranslation('auth')(withStyles(styles)(DeletedAccount));
