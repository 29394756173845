import { useMutation } from '@apollo/client';
import { ADD_COMPANY_SPECIFIC_SYNONYM } from '../graphql/mutations';
import { useCallback } from 'react';

const useAddCompanySynonyms = data => {
  const [createCompanySynonym, { error, loading }] = useMutation(ADD_COMPANY_SPECIFIC_SYNONYM);

  const useAddCompanySpecificCallback = useCallback(async () => {
    await createCompanySynonym({
      variables: { record: { ...data } },
      update: cache => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'customerSpecificSynonyms' });
      },
    });
  }, [createCompanySynonym, data]);

  return {
    addCompanySpecific: useAddCompanySpecificCallback,
    isLoading: loading,
    error,
  };
};

export default useAddCompanySynonyms;
