import React, { useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { VisuallyHidden } from '@reach/visually-hidden';

import { ButtonCustom } from '../../components';

const IdlenessNotificationModal = ({ t }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="idle-notification-title"
    >
      <VisuallyHidden id="idle-notification-title">Idle notification</VisuallyHidden>
      <DialogContent>
        <DialogContentText variant="body1">
          {t('common:idleness_notification_description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonCustom onClick={() => setIsOpen(false)} color="primary">
          {t('common:idleness_notification_cta')}
        </ButtonCustom>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation('common')(IdlenessNotificationModal);
