import { ApiHeaders } from '@people-analytix/shared';
import { navigateExternal, navigateInternal } from 'UI/lib';
import {
  getOpportunityFilterStorageKey,
  OPPORTUNITY_FILTER_SUFFIXES,
} from '../ui/pages/restrict/opportunities/OpportunitiesFilterPanel/OpportunitiesFilterContext/useOrganizationFilterSuffix';
import getApolloClient from '../getApolloClient';
import getBackend from '../getBackend';
import { AUTH_ERROR_CODE_QUERY_PARAM } from './constants';
import { getIsInternalUrl } from 'UI/lib/getUrlType';

const getUserAuthUrl = (backendUrl, userId) => `${backendUrl}/auth/user/${userId}`;

const handleNavigation = redirectUrlOrPath => {
  const isInternalRedirect = getIsInternalUrl(redirectUrlOrPath);
  const navigate = isInternalRedirect ? navigateInternal : navigateExternal;
  navigate(redirectUrlOrPath);
  return;
};

export const getBrowserUserCredentials = () => {
  const palyxUserId = localStorage.getItem('palyxUserId');
  const palyxToken = localStorage.getItem('palyxToken');

  return {
    palyxUserId,
    palyxToken,
  };
};

export const setBrowserUserCredentials = userCredentials => {
  const { palyxUserId, palyxToken } = userCredentials;

  if (palyxUserId) {
    localStorage.setItem('palyxUserId', palyxUserId);
  }

  if (palyxToken) {
    localStorage.setItem('palyxToken', palyxToken);
  }
};

const removeUserStorage = () => {
  localStorage.removeItem('lastActionTimestamp');
  localStorage.removeItem('palyxUserId');
  localStorage.removeItem('palyxToken');

  Object.values(OPPORTUNITY_FILTER_SUFFIXES).forEach(suffix => {
    localStorage.removeItem(getOpportunityFilterStorageKey(suffix));
  });
  localStorage.removeItem('personalDevelopmentFilters');
};

/**
 * Logs out the user and redirect. If redirectOverride is provided, it will be used instead of the default redirect.
 * @param {string} redirectOverride - The redirect url or path. If false, redirect will be skipped.
 * @return {Promise.<void>}
 */
export const logoutUser = async redirectOverride => {
  const backendUrl = getBackend();
  const apolloClient = getApolloClient();
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();
  const actions = [];

  if (palyxToken) {
    actions.push(
      fetch(`${getUserAuthUrl(backendUrl, palyxUserId)}/logout`, {
        headers: {
          [ApiHeaders.Context.UserToken.key]: palyxToken,
        },
        method: 'POST',
      })
    );
  }

  if (apolloClient) {
    actions.push(apolloClient.clearStore());
  }

  const [logoutRes] = await Promise.all(actions);

  removeUserStorage();

  if (redirectOverride) {
    handleNavigation(redirectOverride);
    return;
  }

  if (redirectOverride === false) {
    return;
  }

  if (logoutRes?.status === 200) {
    const body = await logoutRes.json();
    const isValidRedirectUrl = body?.redirectUrl?.startsWith('http');
    if (isValidRedirectUrl) {
      navigateExternal(body.redirectUrl);
      return;
    }
  }

  navigateInternal('/logged-out');
};

/**
 * @return {Promise.<void>}
 */
export const checkUserSession = async () => {
  const backendUrl = getBackend();
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();

  const isUserLogged = Boolean(palyxToken && palyxUserId);

  if (!isUserLogged) {
    return;
  }

  const response = await fetch(
    `${getUserAuthUrl(backendUrl, palyxUserId)}/info?isCheckIdpSession=true`,
    {
      headers: { [ApiHeaders.Context.UserToken.key]: palyxToken },
    }
  );

  if (response.ok) {
    return;
  }

  // Unauthorized
  if (response.status === 401) {
    await logoutUser(window.location.pathname);
    return;
  }

  // Other error
  await logoutUser(`/?${AUTH_ERROR_CODE_QUERY_PARAM}=${response.status}`);
};
