import { Grid, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import defaults from 'lodash/defaults';
import React, { useCallback, useEffect, useState } from 'react';
import { useT } from 'UI/lib';
import Notes from '../../../../../../components/Notes/Notes';
import { MultipleDocumentUrlInputs } from '../../../Recommendations/components/MultipleDocumentUrlInputs/MultipleDocumentUrlInputs';
import { useStatuses } from '../../../Recommendations/useStatuses';
import DateInput from '../components/DateInput';
import SubmitArea from '../components/SubmitArea';
import styles from './CheckIn.styles';
import UsersSelect from './components/UsersSelect';
import useCheckIn from './useCheckIn';

const useStyles = makeStyles(styles);

export const CheckIn = ({
  isEditMode = false,
  isViewOnly = false,
  measureId,
  discussionPartner,
  startDate,
  status,
  notes,
  metadata,
  onFinish,
  targetUserId,
}) => {
  const t = useT();
  const classes = useStyles();
  const statuses = useStatuses();
  const [mergedUrls, setMergedUrls] = useState('');

  const [form, setForm] = useState(() =>
    defaults(
      {
        discussionPartnerId: discussionPartner?._id,
        startedAt: format(startDate ? new Date(startDate) : new Date(), 'yyyy-MM-dd'),
        status,
        url: metadata?.url,
        notes,
      },
      {
        discussionPartnerId: null,
        startedAt: '',
        status: 'planned',
        url: '',
        notes: '',
      }
    )
  );

  useEffect(() => {
    setForm(form => ({ ...form, url: mergedUrls }));
  }, [mergedUrls]);

  const { addCheckinToPersonalDevelopment, updateCheckinInPersonalDevelopment } = useCheckIn({
    onFinish,
    measureId,
    targetUserId,
  });

  const handleChange = useCallback(
    (field, value) => setForm(oldForm => ({ ...oldForm, [field]: value })),
    []
  );

  const isSubmitButtonEnabled = form.discussionPartnerId && form.startedAt;

  return (
    <Grid container data-test="check-in__content">
      <Grid item xs={12}>
        <Grid container spacing={10} className={classes.detailsSection}>
          <Grid item xs={12} flexDirection="column" data-test="name">
            <InputLabel variant="standard">
              {t('restrict:pd.add_further_goals.check-in.name')}
            </InputLabel>
            <UsersSelect
              id="fullName"
              value={discussionPartner}
              isViewOnly={isEditMode || isViewOnly}
              onValueChange={handleChange}
              label={t('restrict:pd.add_further_goals.check-in.name')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              id="startedAt"
              label={t('restrict:pd.add_further_goals.check-in.date')}
              value={form.startedAt}
              onDateChange={handleChange}
              disabled={isViewOnly}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl data-test="status-select-form">
              <InputLabel variant="standard" shrink>
                {t('restrict:pd.status_field')}
              </InputLabel>
              <Select
                labelId="status-label"
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                disabled={isViewOnly}
                data-test="check-in__status"
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <MultipleDocumentUrlInputs
              mergeUrls={setMergedUrls}
              isDisabled={isViewOnly}
              urls={form.url}
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_document_url'
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Notes
              value={form.notes}
              onValueChange={value => handleChange('notes', value)}
              label={t('restrict:personal_development.recommendations_add_project_to_plan_notes')}
              disabled={isViewOnly}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={20}>
        <SubmitArea
          isDisabled={isViewOnly}
          onFinish={onFinish}
          isSubmitButtonEnabled={isSubmitButtonEnabled}
          buttonLabel={
            isEditMode
              ? t('restrict:pd.update_goals.save_button')
              : t('restrict:pd.add_further_goals.submit_button')
          }
          tooltipLabel={t(
            'restrict:pd.add_further_goals.add_manually.submit_button_disabled.tooltip'
          )}
          onSubmit={() =>
            isEditMode
              ? updateCheckinInPersonalDevelopment(form)
              : addCheckinToPersonalDevelopment(form)
          }
        />
      </Grid>
    </Grid>
  );
};
