import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useT } from 'UI/lib';
import { useMessageContext } from '../../../../../../contexts';
import { useGlobalSearchContext } from '../../../../../../contexts/globalSearch';
import Search from '../../../../../restrict/learn-v2/Course/SkillsFilterPanel/Search/Search';
import SelectedSkills from '../../../components/SelectedSkills';
import { swapSkillSynonymToSkillType } from '../../../../../../lib/swapSkillSynonymToSkillType';
import styles from './styles';

const useStyles = makeStyles(styles);

const Skills = ({ isEditMode, skills, onSkillsChange, clearInputOnSelect }) => {
  const t = useT();
  const classes = useStyles();
  const message = useMessageContext();
  const globalSearch = useGlobalSearchContext();

  const handleSearch = useCallback(
    text => {
      if (!text || !text.trim()) {
        return;
      }
      globalSearch.search(text, ['SkillSynonym']);
    },
    [globalSearch]
  );

  const onSkillsChangeCallback = useCallback(
    newSkill => {
      const selectedSkill = swapSkillSynonymToSkillType(newSkill);
      const isSkillOrSynonymAlreadyAdded = skills.some(skill =>
        skill._id.includes(selectedSkill._id)
      );

      if (isSkillOrSynonymAlreadyAdded) {
        const alreadyAddedSkill = skills.find(skill => skill._id.includes(selectedSkill._id));
        const isExactlySameSkill = alreadyAddedSkill._id === selectedSkill._id;

        message.setMessage({
          type: 'error',
          text: isExactlySameSkill
            ? t('restrict:skills.you_already_have_selected_skill')
            : t('restrict:skills.you_already_have_skill_synonym', {
                synonym: selectedSkill.synonym.synonymString,
                name: alreadyAddedSkill.synonym.synonymString,
              }),
        });

        onSkillsChange([...skills]);
        return;
      }

      onSkillsChange([...skills, selectedSkill]);
    },
    [skills, onSkillsChange] // eslint-disable-line
    // adding message in dependency causes infinite loop
  );

  return (
    <div className={classes.skills}>
      {!isEditMode && (
        <SelectedSkills isEditMode={isEditMode} skills={skills} withInteractiveSkills={false} />
      )}

      {isEditMode && (
        <div className={classes.value}>
          <Search
            id="skillsSearch"
            result={globalSearch.state.data}
            loading={globalSearch.state.loading}
            onSearch={handleSearch}
            onSelect={onSkillsChangeCallback}
            clearInputOnSelect={clearInputOnSelect}
          />
        </div>
      )}
    </div>
  );
};

export default Skills;
