const loadConfig = async configFile => {
  const configUrl = `${process.env.BACKEND_URL}/${configFile}?ver=${process.env.VERSION}`;
  const response = await fetch(configUrl);

  if (!response.ok) {
    throw new Error(`Failed to fetch ${configFile}: ${response.status}`);
  }

  return response.json();
};

const loadTenantConfig = async () => {
  const [whiteLabel, clientConfig] = await Promise.all([
    loadConfig('whiteLabel.js'),
    loadConfig('clientConfig.js'),
  ]);

  window.clientConfig = clientConfig;
  window.PALYX_WHITE_LABEL = whiteLabel;
  window.rootOrganizationId = clientConfig?.cms?.organizationId;
};

export default loadTenantConfig;
