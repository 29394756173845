import { Tooltip } from '@mui/material';

const BasicTooltip = ({
  placement,
  title,
  sxTooltip,
  sxArrow,
  disablePortal,
  children,
  ...props
}) => {
  return (
    <Tooltip
      placement={placement}
      title={title}
      componentsProps={{
        tooltip: {
          sx: theme => ({
            boxShadow: '#000 1px 2px 5px',
            backgroundColor: theme.palette.custom.primary,
            fontSize: 16,
            margin: 5,
            textAlign: 'center',
            ...sxTooltip,
          }),
        },
        arrow: {
          sx: theme => ({
            color: theme.palette.custom.primary,
            ...sxArrow,
          }),
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default BasicTooltip;
