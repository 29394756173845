import React from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    paddingRight: '70px',
    paddingLeft: '70px',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
});

const Container = ({ children, className, classes, ...others }) => (
  <Grid container className={classNames(className, classes.root)} {...others}>
    {children}
  </Grid>
);

export default withStyles(styles)(Container);
