const LoggedOutStyles = theme => ({
  rootWrapper: {
    height: '100%',
    background: theme.customPages.loggedOut.background,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      background: 'none',
      textAlign: 'center',
    },
  },
  h4: {
    lineHeight: 1,
  },
  h1: {
    lineHeight: 1,
  },
  textWrapper: {
    margin: '20px 0',
  },
});

export default LoggedOutStyles;
