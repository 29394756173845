import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Select, Typography } from '@mui/material';
import { useT } from 'UI/lib';
import MenuItem from '@mui/material/MenuItem';
import { ButtonCustom } from '../../components';
import { DataGrid } from '@mui/x-data-grid';
import DataGridPagination from '../../components/DataGridPagination';
import { reportTypeFile } from './constants';
import useConfig from 'UI/customHooks/useConfig';
import get from 'lodash/get';

const defaultSort = { field: 'createdAt', sort: 'desc' };

const ReportDownload = ({
  reportColumns,
  useGetAllReports,
  generateReport,
  reportTypes,
  hideSelect,
  typeReport,
  enableXLS,
  enableCSV,
}) => {
  const t = useT();
  const config = useConfig();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState(defaultSort);
  const { loading, reports, refetch, totalReports } = useGetAllReports({ page, perPage, sort });
  const [tableData, setTableData] = useState({ totalReports: totalReports });
  const [reportsData, setReportsData] = useState(reports);

  const [selectedReport, setSelectedReport] = useState(reportTypes[0].key || '');
  const generate = useCallback(
    async typeFile => {
      switch (typeReport) {
        case 'company-report':
          await generateReport(selectedReport, typeFile);
          break;
        default:
          await generateReport(typeFile);
          break;
      }
      await refetch();
    },
    [generateReport, refetch, selectedReport, typeReport]
  );

  useEffect(() => {
    if (!loading) {
      setReportsData(reports);
      setTableData({ totalReports: totalReports });
    }
  }, [reports, totalReports, loading]);

  const onPageChange = useCallback(pageNumber => {
    setPage(pageNumber);
  }, []);

  const onPageSizeChange = useCallback(newSize => {
    setPerPage(newSize);
  }, []);

  const onOrderChange = useCallback(input => {
    if (input.length) {
      setSort(input[0]);
    } else {
      setSort(defaultSort);
    }
  }, []);

  return (
    <Box sx={theme => ({ maxWidth: `${theme.maxWidth - 48}px`, width: '80vw' })}>
      <Grid
        container
        sx={theme => ({ maxWidth: `${theme.maxWidth - 48}px`, width: '80vw' })}
        height={80}
        spacing={4}
        alignItems="flex-end"
        paddingBottom={4}
      >
        {!hideSelect && (
          <Grid xs={3} item paddingTop={4}>
            <Select
              autoWidth
              variant="standard"
              value={selectedReport}
              onChange={e => {
                setSelectedReport(e.target.value);
              }}
              data-test="company-report-select"
            >
              {reportTypes.map(report => (
                <MenuItem
                  key={report.key}
                  data-test={`company-report-select-item-${report.key}`}
                  value={report.key}
                >
                  {t(get(config, `cms.features.modal.reports.labels.${report.key}`, report.text))}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid
          container
          item
          xs
          spacing={2}
          justifyContent="flex-start"
          sx={{ flexWrap: 'initial' }}
        >
          {enableXLS && (
            <Grid item>
              <ButtonCustom
                data-test="company-report-generate-XLS-button"
                onClick={() => generate(reportTypeFile.XLS)}
              >
                <span style={{ wordBreak: 'break-word' }}>
                  {t('restrict:company_view.report.generate_XLS_button')}
                </span>
              </ButtonCustom>
            </Grid>
          )}
          {enableCSV && (
            <Grid item>
              <ButtonCustom
                data-test="company-report-generate-CVS-button"
                onClick={() => generate(reportTypeFile.CSV)}
              >
                <span style={{ wordBreak: 'break-word' }}>
                  {t('restrict:company_view.report.generate_CSV_button')}
                </span>
              </ButtonCustom>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box mt={4} mb={2}>
        <Typography variant="h3">{t && t('restrict:company_view.report.header')}</Typography>
      </Box>
      <Grid sx={{ height: 550 }} data-test="modal-report-table-container">
        <DataGrid
          paginationMode="server"
          sortingMode="server"
          disableColumnFilter
          disableSelectionOnClick
          disableColumnMenu
          rows={reportsData}
          columns={reportColumns}
          rowCount={tableData.totalReports || 0}
          page={page}
          pageSize={perPage}
          onSortModelChange={onOrderChange}
          components={{
            Pagination: DataGridPagination,
          }}
          loading={loading}
          getRowHeight={() => 60}
          componentsProps={{
            pagination: {
              rowCount: tableData.totalReports || 0,
              onPageChange: onPageChange,
              page: page,
              onPageSizeChange: onPageSizeChange,
              perPage: perPage,
            },
            row: {
              'data-test': 'modal-report-table-row',
            },
          }}
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
      </Grid>
    </Box>
  );
};

export default ReportDownload;
